import React, { Component } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Card } from 'reactstrap';
import { deleteRecipe } from '../../datafetchs/recipes';
export default class RecipeList extends Component {
    static displayName = RecipeList.name;

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        console.debug("Entering component=RecipeList");
    }
    state = {
        recipes: [],
        loading: true,
        modal: false,
        id_recipeToDelete:0
    };
    
     toggle(e) {
        if (e) {
            var recipeTodelete = e.target.parentNode.getAttribute("postid");
            e.preventDefault();
            this.setState({
                modal: !this.state.modal,
                id_recipeToDelete: recipeTodelete
            });
        }
        else {
            this.setState({
                modal: !this.state.modal,
                id_recipeToDelete:0
            });

        }
    }
    
    handleDelete(e) {

        e.preventDefault();
        var id = this.state.id_recipeToDelete;
        this.toggle();
        deleteRecipe(this.props.auth.kc_token, id).then(() => {
            if (this.state.id_recipeToDelete !== 0) {
                this.setState({
                    id_recipeToDelete: 0
                });
            }
            this.props.onSave();
        });

    }

    render() {
        let myValue = this.state.recipes;
        if (this.state.recipes.length !== this.props.recipesFiltering.length && this.props.recipesFiltering) {
            myValue = this.props.recipesFiltering;
        }
        return (
            <Card name="card" style={{ width: 'auto', height: "500px", overflow: "scroll", marginTop: "10px", marginBottom: "10px", paddingLeft: "10px" }}>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Variante</th>
                        <th>Quantité</th>
                        <th>Uniquement par famille</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {myValue.map(recipe => (
                        <tr key={recipe.id}>
                            <td>{recipe.name}</td>
                            <td>{recipe.variant}</td>
                            <td>{recipe.globalQuantity}</td>
                            <td><FormGroup switch>
                                <Input
                                    type="switch"
                                    checked={recipe.isOneByFamily}
                                    disabled
                                    onChange={e => {}}
                                />
                                </FormGroup>
                            </td>
                            <td postid={recipe.id}>
                                <Button onClick={() => this.props.editGlobalRecipe(recipe)}>Editer</Button>
                                {' '}
                                <Button onClick={() => this.props.onViewRecipeIngredient(recipe)}>Voir les ingrédients</Button>
                                {' '}
                                <Button onClick={() => this.props.onViewStepsRecipe(recipe)}>Voir les étapes</Button>
                                {' '}
                                <Button color="danger" id={recipe.id} onClick={(e) => this.toggle(e)}>Supprimer</Button>
                                {' '}
                                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                    <ModalHeader toggle={this.toggle}>Confirmation suppresion</ModalHeader>
                                    <ModalBody>
                                        Êtes-vous sure de vouloir supprimer la recette?'
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={(e) => this.handleDelete(e, recipe.id)}>
                                            Oui
                                        </Button>{' '}
                                        <Button color="secondary" onClick={this.toggle}>
                                            Non
                                        </Button>
                                    </ModalFooter>
                                    </Modal>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </Table>
            </Card>
        );
    }

}
