import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, CardSubtitle, CardText, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RecipeEditIngredient from './RecipeEditIngredient';
import { deleteIngredientRecipe, getIngredientsRecipe } from '../../datafetchs/recipes';
import { addIngredientsRecipe } from '../../datafetchs/recipes';

export default class RecipeModifyIngredients extends Component {
    constructor(props) {
        super(props);    
        this.toggle = this.toggle.bind(this);
        console.debug("Entering component=RecipeModifyIngredients");
    }
    state = {
        recipe: this.props.recipe,
        ingredientsListNotInRecipe: this.props.ingredientsListNotInRecipe,
        listIngredientTodisplay: this.props.ingredientsListNotInRecipe,
        inputSearchIngredient: "",
        inputQuantityIngredient: "",
        changeRecipeIngredient: null,
        allIngredients: this.props.allIngredients,
        modal: false,
        erreurMessage: "",
        recipeIngredients: this.props.ingredientAlreadyInList// Initialize with an empty object
    };

    //helper function

    getIngredientForRecipe = () => {
        getIngredientsRecipe(this.props.auth.kc_token, this.state.recipe.id)
            .then(response => {
                if (response) {

                    var temp_response = [];
                    for (var i = 0; i < response.length; i++) {
                        var tempObj = response[i];
                        tempObj.quantityEstimate = tempObj.quantity;
                        temp_response.push(tempObj);
                    }
                    var ingredientNotInRecipeList = this.getListofIngredientNotInRecipeList(temp_response, this.state.allIngredients);
                    this.setState({ recipeIngredients: temp_response, ingredientNotInRecipeList: ingredientNotInRecipeList, inputSearchIngredient: '', listIngredientTodisplay: ingredientNotInRecipeList, changeRecipeIngredient:null,erreurMessage:"" });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the recipe ingreidents:", error);
            });
    }
    toggle(e) {
        this.setState({
            modal: !this.state.modal
        });
    }

    getListofIngredientNotInRecipeList = (ingredientsRecipe, allIngredients) => {
        var ingredientsListNotInRecipe = [];
        for (let i = 0; i < allIngredients.length; i++) {
            var tempObjIngredient = allIngredients[i];
            var ingredientId = tempObjIngredient.id;
            var booleanNoDetect = true;
            for (let compt = 0; compt < ingredientsRecipe.length; compt++) {
                var tempObjRecipeIngredient = ingredientsRecipe[compt];
                var recipeIngredientId = tempObjRecipeIngredient.ingredientId;
                if (ingredientId === recipeIngredientId) {
                    booleanNoDetect = false;
                    compt = ingredientsRecipe.length;
                }
            }
            if (booleanNoDetect) {
                ingredientsListNotInRecipe.push(tempObjIngredient);
            }
        }
        return ingredientsListNotInRecipe;
    }

    handleSaveEdition = () => {
        this.getIngredientForRecipe();
        
    }

    handleIngredientModifyCancel = () => {
        // Once the ingredient is saved or added, revert back to the list
        
        this.setState({ changeRecipeIngredient: null });
    }

    handleRemoveIngredient = (e,recipeIngredient) => {
        deleteIngredientRecipe(this.props.auth.kc_token, recipeIngredient.id).then(response => {
            this.getIngredientForRecipe();

        })
            .catch(error => {
                console.error("There was an issue removing the recipe ingriedents:", error);
            });
    }

   

    handleSearchIngredient = (e) => {
        this.setState({ inputSearchIngredient: e.target.value });

    }

    handleChangeIngredient = (ingredientRecipe) => {
        this.setState({
            changeRecipeIngredient: ingredientRecipe,
        });

    }
    handleChange(e) {
        
    }
    
    componentDidMount() {
    }

   
    addIngredientInRecipe(e,ingredient) {
        var quantity = e.target.parentNode.parentNode.children[2].children[0].children[0].children[0].value;
        var unitRef = e.target.parentNode.parentNode.children[2].children[0].children[1].children[0].value;
        var quantityFloat = parseFloat(quantity);
        if (unitRef !== "" &&quantityFloat!==0&&quantity!=="") {
            var recipeIngredient = {};
            recipeIngredient.recipeId = this.state.recipe.id;
            recipeIngredient.ingredientId = ingredient.id;
            recipeIngredient.quantity = quantityFloat;
            recipeIngredient.unitRef = unitRef;
            addIngredientsRecipe(this.props.auth.kc_token, recipeIngredient).then(response => {
                this.getIngredientForRecipe();
            })
                .catch(error => {
                    console.error("There was an issue getting the recipe ingreidents:", error);
                    this.setState({ erreurMessage: "Problème lors de l'ajout d'un ingrédient à la recette" });
                });
        }
        else {
            this.setState({erreurMessage:"Merci de choisir une quantité et une référence d'unité"})

        }

    }
    handleKeyPress = (e) => {

        if (e.key === "Enter") {
            this.doSearch();
        }
    }
    handleSubmit(e) {
        
    }
    handleCancel(e) {
        e.preventDefault();
        this.props.onSave();
    }

    doSearch = () => {
        const data = this.state.ingredientsListNotInRecipe;
        var newData = [];
        for (var i = 0; i < data.length; i++) {
            var temp_Ingredients = data[i];
            var temp_name = temp_Ingredients.name;
            if (temp_name.includes(this.state.inputSearchIngredient)) {
                newData.push(data[i]);
            }
        }
        this.setState({
            listIngredientTodisplay:newData
        });
    }
    render() {
        if (this.state.changeRecipeIngredient) {
            return <RecipeEditIngredient auth={this.props.auth} ingredientToEdit={this.state.changeRecipeIngredient} recipe={this.state.recipe} onSave={this.handleIngredientModifyCancel} onSudmitEdition={this.handleSaveEdition} />;
        }

        return (
            <Form>
                <FormGroup row>
                    <Col sm={10}>
                        <Card name="card" body color="light" style={{ width: 'auto', height:"150px" }}>
                            <CardBody>

                                <CardTitle tag="h5">{this.state.recipe.name}</CardTitle>
                                <CardSubtitle className="mb-2 text-muted" tag="h6">
                                    Variante:{this.state.recipe.variant}
                                </CardSubtitle>
                                <CardText>Quantité de la recette:{this.state.recipe.globalQuantity} personnes</CardText>
                            </CardBody>
                            </Card>
                    </Col>
                    <Col sm={2}>
                        <Button onClick={(e) => this.handleCancel(e)}>Retour</Button>
                    </Col>
                </FormGroup>
                <Card name="card" style={{ width: 'auto', height: "400px", overflow: "scroll", marginTop: "10px" }}>
                    <CardBody>

                        <CardTitle tag="h5">Ingrédients de la recette</CardTitle>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nom de l'ingrédient</th>
                                    <th>Variante</th>
                                    <th>Quantité</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.recipeIngredients.map(ingredientRecipe => (
                                    <tr key={ingredientRecipe.id}>
                                        <td>{ingredientRecipe.ingredientName}</td>
                                        <td>{ingredientRecipe.ingredientVariant}</td>
                                        <td>{ingredientRecipe.quantity}  {ingredientRecipe.unitRef}</td>
                                        <td>
                                        <Button onClick={() => this.handleChangeIngredient(ingredientRecipe)}>Ajuster quantité</Button>
                                        {' '}
                                        <Button color="danger" onClick={(e) => this.handleRemoveIngredient(e, ingredientRecipe)}>Retirer l'ingrédient</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Card name="card" style={{ width: 'auto', height: "400px", overflow: "scroll", marginTop: "10px" }}>
                    <CardBody>
                        <CardTitle tag="h5">Ingrédients disponibles à l'ajout</CardTitle>
                        <FormGroup row>
                            <Label for="searchIngredient" sm={2}>Rechercher votre ingrédient</Label>
                            <Col sm={8}>
                                <Input type="search" name="searchIngredient" id="searchIngredient" value={this.state.inputSearchIngredient} onChange={this.handleSearchIngredient.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} />
                            </Col>
                            <Col sm={2}>
                                <Button color="primary" onClick={this.doSearch}>Rechercher</Button>
                            </Col>
                        </FormGroup>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nom de l'ingrédient</th>
                                    <th>Variante</th>
                                    <th>Quantité pour {this.state.recipe.globalQuantity} personnes</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listIngredientTodisplay.map(ingredient => (
                                    <tr key={ingredient.id}>
                                        <td>{ingredient.name}</td>
                                        <td>{ingredient.variant}</td>
                                        <td>
                                            <FormGroup row>
                                                <Col sm={5}><Input type="text" name="ingredient_quantity" id="ingredient_quantity" onChange={this.handleChange.bind(this)} /></Col>
                                                <Col sm={3}><Input type="select" name="primaryunit" id="primaryunit" onChange={this.handleChange.bind(this)}>
                                                    <option>{ingredient.primaryunit}</option>
                                                    <option>
                                                        {(ingredient.estimationunit && ingredient.estimationunit !== "0" && ingredient.primaryunit !== "Unité") && "Unité"} 
                                                    </option>
                                            </Input> 
                                                </Col>
                                            </FormGroup>
                                        </td>
                                        <td><Button onClick={(e) => { this.addIngredientInRecipe(e,ingredient) }}>Ajouter</Button></td>              
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                       
                    </CardBody>

                </Card>
                <div style={{ color: "red" }}>{this.state.erreurMessage}</div>
                <Button style={{marginTop:'2rem'}} onClick={(e) => this.handleCancel(e)}>Retour</Button>
            </Form>
        );
    }
}