
const getHeaders = (kc_token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${kc_token}`,
    };
};

const execReq = async (url, method, kc_token, data) => {
    let options = {
        method: method,
        headers: getHeaders(kc_token),
    }
    if (data) {
        options.body = JSON.stringify(data);
    }
    return await fetch(url, options);
};


export const getExecReq = async (url, kc_token) => {
    return await execReq(url, 'GET', kc_token);
};

export const postExecReq = async (url, kc_token, data) => {
    return await execReq(url, 'POST', kc_token, data);
};


export const delExecReq = async (url, kc_token) => {
    return await execReq(url, 'DELETE', kc_token);
};


export const putExecReq = async (url, kc_token, data) => {
    return await execReq(url, 'PUT', kc_token, data);
};

export const patchExecReq = async (url, kc_token, data) => {
    return await execReq(url, 'PATCH', kc_token, data);
};
