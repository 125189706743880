import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { updateRecipe } from '../../datafetchs/recipes';
export default class RecipeEditGlobal extends Component {
    constructor(props) {
        super(props);
        console.debug("Entering component=RecipeEditGlobal");
    }
    state = {
        recipe: this.props.recipe || {},
        disableButton: true,
        errorMessage:""
};
    handleChangeSwitch = (e) => {
        var recipe = this.state.recipe;
        recipe.isOneByFamily = !recipe.isOneByFamily;
        this.setState({ recipe: recipe, disableButton: false });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            recipe: {
                ...prevState.recipe,
                [name]: value
            },
            disableButton:false
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        updateRecipe(this.props.auth.kc_token, this.state.recipe).then(() => {
            this.props.onSave();
        });
    }

    handleCancel(e) {
        e.preventDefault();
        this.props.onCancel();
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Label for="name">Nom</Label>
                    <Input type="text" name="name" id="name" value={this.state.recipe.name || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="variant">Variante</Label>
                    <Input type="text" name="variant" id="variant" value={this.state.recipe.variant || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="globalQuantity">Nombre de personnes</Label>
                    <Input type="number" name="globalQuantity" id="globalQuantity" value={this.state.recipe.globalQuantity || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup switch>
                    <Label for="is_oneByFamily">
                        Une par famille (mithan ou kharass)?*
                    </Label>
                    <Input id="is_oneByFamily" name="is_oneByFamily" type="switch" checked={this.state.recipe.isOneByFamily} onChange={this.handleChangeSwitch.bind(this) }>
                    </Input>
                </FormGroup>
                <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                <Button color="sucess" type="submit" disabled={this.state.disableButton }>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
            </Form>
        );
    }
}