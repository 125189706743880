import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (
      <div>
        <h1>Page d’accueil</h1>
        <p>Bienvenue sur l'application Calculateur de FMB. Pour accèder à l'application, merci de cliquer sur le bouton "Se connecter"</p>
      </div>
    );
  }
}
