
import React, { Component } from 'react';
import { Button, Table, Label, Input, Col, FormGroup,  Card } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';


export default class CalculatorResult extends Component {
    constructor(props) {
        super(props);
        console.debug("Entering component=CalculatorResult");
    }
    state = {
        ingredientListByRecipe:this.props.ingredientListByRecipe,
        ingredientListsTotal: this.props.ingredientListsTotal,
        viewSelection: this.props.viewSelection,
        totalCost: this.props.totalCost
    }
    componentDidMount() {
    }

    handleChange(e) {
    }

    handleChangeView = (e) => {
        var value = e.target.value;
        this.setState({ viewSelection: value });
    }

    saveResultForHistorical = () => {

    }

   

    render() {
        if (this.props.viewSelection === "INGREDIENTS") {
            return (
                <div>
                    <Card color="light" style={{ marginTop: "1rem" }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Ingrédients</th>
                                <th>Quantité</th>
                                <th>Prix</th>
                                <th>Stock présent</th>
                                <th>Stock restant après simulation</th>
                                <th>Etat du stock post utilisation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.ingredientListsTotal.map(ingredient => (
                                <tr key={ingredient.ingredientId}>
                                    <td>{ingredient.ingredientName} {ingredient.ingredientVariant}</td>
                                    <td>{ingredient.ingredientQuantity} {ingredient.ingredientprimaryUnity}</td>
                                    <td>{ingredient.ingredientPrice} euros</td>
                                    <td>{ingredient.ingredientStock} {ingredient.ingredientprimaryUnity}</td>
                                    <td>{ingredient.remainingStock} {ingredient.ingredientprimaryUnity}</td>
                                    <td style={{color:ingredient.colorStock,fontWeight:"bold"} }>{ingredient.stateStock}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    </Card>
                    <FormGroup row>
                    <Col sum={7}>
                        <div style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "16px", fontWeight: "Bold" }}>Coût total {this.props.totalCost} euros</div>
                    </Col>
                    <Col sum={3}>
                       <Button style={{ marginTop: "2rem", marginBottom: "2rem" }} onClick={this.saveResultForHistorical}>Sauvegarder</Button>
                    </Col>
                    </FormGroup>
                </div>);

        }
        else if (this.props.viewSelection === "CATEGORIES") {
            return (
                
                    <div>
                    {this.props.ingredientsListByCategory.map(category => (
                        <>
                            <Card color="light" style={{ marginTop: "1rem" }}>
                            <Label style={{ backgroundColor: "light", fontWeight: "bold", paddingLeft: "10px", color: 'blue' }}>{category.categoryName}</Label>
                            <Table>
                                    <thead>
                                        <tr>
                                            <th>Ingredients</th>
                                            <th>Quantité</th>
                                            <th>Prix</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {category.ingredients.map(ingredient => (
                                            <tr key={ingredient.ingredientId}>
                                                <td>{ingredient.ingredientName} {ingredient.ingredientVariant}</td>
                                                <td>{ingredient.ingredientQuantity} {ingredient.ingredientprimaryUnity}</td>
                                                <td>{ingredient.ingredientPrice} euros</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div>Coût de la catégorie : {category.costCategory}</div>
                            </Card>
                        </>

                    ))
                }
                    <FormGroup row>
                        <Col sum={7}>
                            < div style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "16px", fontWeight: "Bold" }
                            }> Coût total {this.props.totalCost} euros</div >
                        </Col>
                        <Col sum={3}>
                            <Button style={{ marginTop: "2rem", marginBottom: "2rem" }} onClick={this.saveResultForHistorical}>Sauvegarder</Button>
                        </Col>
                    </FormGroup>
                </div >);
            
        }
        else {
            return (
                <div>

                    {this.props.ingredientListByRecipe.map(recipe => (
                        <>
                            <Card color="light" style={{ marginTop: "1rem" }}>
                            <FormGroup row>
                                <Col sum={3}>
                                    <Label style={{ backgroundColor: "light", fontWeight: "bold", paddingLeft: "10px", color: 'blue' }}>{recipe.recipeName}</Label>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Recette par famille</Label>
                                        <FormGroup switch>
                                                <Input
                                                    type="switch"
                                                    checked={recipe.isOneByFamily}
                                                    disabled
                                                />
                                        </FormGroup>
                                    </FormGroup>
                                 </Col>

                            </FormGroup>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Ingredients</th>
                                        <th>Quantité</th>
                                        <th>Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recipe.ingredients.map(ingredient => (
                                        <tr key={ingredient.ingredientId}>
                                            <td>{ingredient.ingredientName} {ingredient.ingredientVariant}</td>
                                            <td>{ingredient.ingredientQuantity} {ingredient.ingredientprimaryUnity}</td>
                                            <td>{ingredient.ingredientPrice} euros</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                                <div>Coût de la recette : {recipe.costRecipe}</div>
                            </Card>
                            </>

                    ))}
                    <FormGroup row>
                        <Col sum={7}>
                            <div style={{ marginTop: "2rem", marginBottom: "2rem", fontSize: "16px", fontWeight: "Bold" }}>Coût total {this.props.totalCost} euros</div>
                        </Col>
                        <Col sum={3}>
                            <Button style={{ marginTop: "2rem", marginBottom: "2rem" }} onClick={this.saveResultForHistorical}>Sauvegarder</Button>
                        </Col>
                    </FormGroup>

                   

                </div>);

        }
    }
}
