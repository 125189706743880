import React, { Component } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import { deleteIngredient } from '../../datafetchs/ingredients';

export default class IngredientList extends Component {
    static displayName = IngredientList.name;

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        console.debug("Entering component=IngredientList");
    }
    state = {
        ingredients: [],
        loading: true,
        modal: false,
        id_ingredientToDelete: 0
    };

    toggle(e) {
        if (e) {
            var ingredientTodelete = e.target.parentNode.getAttribute("postid");
            e.preventDefault();
            this.setState({
                modal: !this.state.modal,
                id_ingredientToDelete: ingredientTodelete
            });
        }
        else {
            this.setState({
                modal: !this.state.modal,
                id_ingredientToDelete: 0
            });
        }
    }

    handleDelete(e) {
        e.preventDefault();
        var id = this.state.id_ingredientToDelete;
        this.toggle();
        deleteIngredient(id, this.props.auth.kc_token).then((response) => {
            if (response.statusCode === 400) {

                alert(response.message);
                this.setState({
                    id_ingredientToDelete: 0
                });
            }
            if (this.state.id_ingredientToDelete !== 0) {
                this.setState({
                    id_ingredientToDelete: 0
                });
            }
            this.props.onSave();
        })

    }

    render() {
        let myValue = this.state.ingredients;

        if (this.state.ingredients.length !== this.props.ingredientFiltering.length && this.props.ingredientFiltering) {
            myValue = this.props.ingredientFiltering;
        }
        return (
            <Card name="card" style={{ width: 'auto', height: "500px", overflow: "scroll", marginTop: "10px", marginBottom:"10px",paddingLeft:"10px" }}>
            <Table>
                <thead>
                <tr>
                    <th>Nom</th>
                    <th>Variante</th>
                    <th>Prix</th>
                    <th>Estimation de l'unité</th>
                    <th>Catégorie</th>
                    <th>Fournisseur</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {myValue.map(ingredient => (
                    <tr key={ingredient.id} postid={ingredient.id}>
                        <td>{ingredient.name}</td>
                        <td>{ingredient.variant}</td>
                        <td>{ingredient.price} {ingredient.currency} / {ingredient.primaryunit}</td>
                        <td>
                            {(ingredient.estimationunit && ingredient.estimationunit!=="0") ? `${ingredient.estimationunit} ${ingredient.primaryunit} / 1 unité`:"NA"}
                            
                        </td>
                        <td>{(ingredient.category && ingredient.category!==" " )? `${ingredient.category}` : "NA"}</td>
                        <td>{(ingredient.supplier && ingredient.supplier!==" ") ? `${ingredient.supplier}` : "NA"}</td>
                        {/* <td>{ingredient.estimationunit} {ingredient.primaryunit} / 1 unité </td>*/}
                        <td postid={ingredient.id}>
                            <Button onClick={() => this.props.onEdit(ingredient)}>Editer</Button>
                            {' '}
                            <Button color="danger" id={ingredient.id} onClick={(e) => this.toggle(e)}>Supprimer</Button>
                            <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
                                <ModalHeader toggle={() => this.toggle()}>Confirmation suppresion</ModalHeader>
                                <ModalBody>
                                    Êtes-vous sure de vouloir supprimer l'ingrédient?'
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={(e) => this.handleDelete(e, ingredient.id)}>
                                        Oui
                                    </Button>{' '}
                                    <Button color="secondary" onClick={this.toggle}>
                                        Non
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
                </Card>
            
        );
    }
}
