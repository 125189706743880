import {recipeIngredientsUrl, recipeStepsUrl, recipesUrl} from "./datafetchUrls";
import {delExecReq, getExecReq, postExecReq, putExecReq} from "./common";

export const getRecipe = async (token) => {
    let ret = [];
    const response = await getExecReq(recipesUrl, token);
    ret = await response.json();
    return ret;
};

export const updateRecipe = async (token, recipe) => {
    putExecReq(recipesUrl, token, recipe);
}

export const deleteRecipe = async (token, id) => {
    delExecReq(`${recipesUrl}/${id}`, token)
}

export const addRecipe = async (token, recipe) => {
    const response = await postExecReq(recipesUrl, token, recipe);
    const data = await response.json(); 
    return data.id;
}

export const getIngredientsRecipe = async (token, recipe_id) => {
    let ret = [];
    var id = recipe_id;
    const response = await getExecReq(`${recipeIngredientsUrl}/${id}`, token);
    ret = await response.json();
    return ret;
}
export const getStepsRecipe = async (token, recipe_id) => {
    let ret = [];
    var id = recipe_id;
    if (id === null || id === "") {
        console.log("Error, the recipe id is not known so can not get the recipe steps");
        ret = null;
    }
    else {
        const response = await getExecReq(`${recipeStepsUrl}/${id}`, token);
        ret = await response.json();
    }
    return ret;

};

export const addIngredientsRecipe = async (token, recipeIngredients) => {
    postExecReq(recipeIngredientsUrl, token, recipeIngredients)
    .then((response) => {
        return JSON.stringify(response);
    });
}

export const addStepRecipe = async (token, stepRecipe) => {
    postExecReq(recipeStepsUrl, token, stepRecipe);
}

export const deleteIngredientRecipe = async (token, id) => {
    delExecReq(`${recipeIngredientsUrl}/${id}`, token);
}

export const deleteStepRecipe = async (token, id) => {
    delExecReq(`${recipeStepsUrl}/${id}`, token)
}

export const updateIngredientRecipe = async (token, ingredientRecipe) => {
    putExecReq(recipeIngredientsUrl, token, ingredientRecipe);
}

export const updateStepRecipe = async (token, stepRecipe) => {
    putExecReq(recipeStepsUrl, token, stepRecipe);
}