import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import {putExecReq} from "../../datafetchs/common";
import {ingredientsUrl} from "../../datafetchs/datafetchUrls";

export default class IngredientEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ingredient: props.ingredient || {},
            disableButton: true
        };
    }

    handleChange(e) {
        var { name, value } = e.target;
        if (name === "price" || name === "estimationunit" || name ==="limitstock") {
            if (value.includes(",")) {
                value = value.replace(",", ".");
            }
        }
        this.setState(prevState => ({
            ingredient: {
                ...prevState.ingredient,
                [name]: value
            },
            disableButton: false,
            errorMessage:""
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        
            putExecReq(`${ingredientsUrl}/${this.state.ingredient.id}`, this.props.auth.kc_token, this.state.ingredient)
            .then(response => {
                if (!response.ok) {
                    this.setState({ errorMessage: "Failed to edit the ingredient, try again" });
                    throw new Error('Failed to edit ingredient');

                }
            }).then(() => {
                this.props.onSave();
            });
         
    }

    handleCancel(e) {
        e.preventDefault();
        this.props.onCancel();
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Label for="name">Nom</Label>
                    <Input type="text" name="name" id="name" value={this.state.ingredient.name || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="price">Prix</Label>
                    <Input type="text" name="price" id="price" value={this.state.ingredient.price || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="currency">Monnaie</Label>
                    <Input type="select" name="currency" id="currency" value={this.state.ingredient.currency || ''} onChange={this.handleChange.bind(this)}>
                        <option>Euros</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="primaryunit">
                        Unité de mesure*
                    </Label>
                    {/* options en dur à potentiellement changer en table*/}
                    <Input id="primaryunit" name="primaryunit" type="select" value={this.state.ingredient.primaryunit || ''} onChange={this.handleChange.bind(this)}>
                        <option>Litre</option>
                        <option>Kg</option>
                        <option>Unité</option>
                        <option>Paquet</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="estimationunit">Estimation de l'unité dans l'unité de mesure</Label>
                    <Input type="text" name="estimationunit" id="estimationunit" value={this.state.ingredient.estimationunit || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>

                <FormGroup>
                    <Label for="variant">Variante</Label>
                    <Input type="text" name="variant" id="variant" value={this.state.ingredient.variant || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="category">Catégorie</Label>
                    <Input type="select" name="category" id="category" value={this.state.ingredient.category || ''} onChange={this.handleChange.bind(this)} >
                        <option> </option>
                        <option>Fruits Légumes</option>
                        <option>Viande</option>
                        <option>Epicerie</option>
                        <option>Epicerie fine</option>
                        <option>Pain viennoiseries</option>
                </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="supplier">Fournisseur</Label>
                    <Input type="select" name="supplier" id="supplier" value={this.state.ingredient.supplier || ''} onChange={this.handleChange.bind(this)} >
                        <option> </option>
                        <option>Fournisseur 1</option>
                        <option>Fournisseur 2</option>
                        <option>Fournisseur 3</option>
                        <option>Fournisseur 4</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="limitstock">Seuil stock limite</Label>
                    <Input type="text" name="limitstock" id="limitstock" value={this.state.ingredient.limitstock || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                {/*... other form groups for other fields */}
                <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                <Button color="sucess" type="submit" disabled={this.state.disableButton} style={{color:"sucess"} }>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
                
            </Form>
        );
    }
}
