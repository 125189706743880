
import React, { Component } from 'react';
import { Button, Form, Table, Label, Input, Col, FormGroup,Collapse,CardText ,Card, CardBody, CardTitle} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CalculatorResult from './CalculatorResult'

export default class CalculatorGlobalInformation extends Component {
    constructor(props) {
        super(props);      
        console.debug("Entering component=CalculatorGlobalInformation");
    }

    state = {
    selectedRecipes: this.props.selectedRecipes,
    thalies: this.props.numberOfThalies,
    families: this.props.numberOfFamilies,
    submitted: this.props.submitted,
    calculationFields: this.props.calculationFields,
    numberOfPersonForThaliesRecipe: this.props.numberOfPersonForThaliesRecipe,
    numberOfPersonForFamiliesRecipe: this.props.numberOfPersonForFamiliesRecipe,
    recipeByThalies: this.props.recipeByThalies,
    recipeByFamilies: this.props.recipeByFamilies,
    viewFamilyBoolean: false,
    viewThaaliBoolean: false,
    viewSelection: "INGREDIENTS",
    
    }

    componentDidMount() {
        
    }

    handleChangeView =(e)=> {
        var value = e.target.value;   
        this.setState({ viewSelection: value });
    }

    changeBooleanViewFamily = () => {     
        if (this.state.viewFamilyBoolean) {
            this.setState({ viewFamilyBoolean: false })
        }
        else {
            this.setState({viewFamilyBoolean:true})
        }
    }
    changeBooleanViewThaali=() =>{
        if (this.state.viewThaaliBoolean) {
            this.setState({ viewThaaliBoolean: false })
        }
        else {
            this.setState({ viewThaaliBoolean: true })
        }
    }

    render() {
        if (this.props.submitted) {

            return (
                <div>
                    <Card style={{ marginTop: "2rem" }}>
                        <CardBody>
                            <CardTitle tag="h5" >
                                Résumé des paramètres de calcul
                            </CardTitle>
                            <Button
                               color="primary"
                                onClick={this.changeBooleanViewFamily}
                            >
                                Afficher/Masquer les details de famille
                            </Button> {' '}
                            <Button
                                color="primary"
                                onClick={this.changeBooleanViewThaali}
                            >
                                Afficher/Masquer les details de thaali
                            </Button>
                            <Collapse isOpen={this.state.viewFamilyBoolean }>
                               
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Détails</th>
                                            <th>Valeurs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key="1">
                                            <td>Nombre de familles</td>
                                            <td>
                                            {this.props.numberOfFamilies }
                                            </td>
                                        </tr>
                                        <tr key="2">
                                            <td>Nombre de recettes par famille</td>
                                                <td>{this.props.recipeByFamilies.length}
                                            </td>
                                        </tr>
                                        <tr key="3">
                                            <td>Nombre de personnes pour le nombre total de familles</td>
                                            <td>{this.props.numberOfPersonForFamiliesRecipe}</td>
                                        </tr>
                                    </tbody>
                                    </Table>
                            </Collapse>
                            <Collapse isOpen={this.state.viewThaaliBoolean }>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Détails</th>
                                        <th>Valeurs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key="1">
                                        <td>Nombre de thalies</td>
                                        <td>
                                            {this.props.numberOfThalies}
                                        </td>
                                    </tr>
                                    <tr key="2">
                                        <td>Nombre de recettes par thalis</td>
                                        <td>{this.props.recipeByThalies.length}
                                        </td>
                                    </tr>
                                    <tr key="3">
                                        <td>Nombre de personnes pour le nombre total de thaalis</td>
                                        <td>{this.props.numberOfPersonForThaliesRecipe}</td>
                                    </tr>
                                </tbody>
                                </Table>
                            </Collapse>
                        </CardBody>
                        
                    </Card>
                    <Card style={{ marginTop: "2rem" }}>
                        <CardBody>
                            
                            <CardTitle tag="h5" >
                                Résultats de simulation
                                </CardTitle>
                            <FormGroup row>
                                <Col sm={7}>
                                    <Input id="viewSelection" name="viewSelection" type="select" value={this.state.viewSelection} onChange={this.handleChangeView}>
                                    <option>INGREDIENTS</option>
                                    <option>RECETTES</option>
                                    <option>CATEGORIES</option>
                                    
                                    </Input>
                                </Col>
                                <Col sm={3}>
                                    <Button>Exporter en pdf</Button>

                                </Col>
                            </FormGroup>   
                            <CalculatorResult ingredientListsTotal={this.props.ingredientListsTotal} viewSelection={this.state.viewSelection} ingredientListByRecipe={this.props.ingredientListByRecipe} totalCost={this.props.totalCost} ingredientsListByCategory={this.props.ingredientsListByCategory }></CalculatorResult>  
                        </CardBody>
                    </Card>
                </div>
            );
        }
        else {

            return (
                <div>
                    <Card style={{marginTop:"2rem"} }>
                        <CardBody>
                            <CardTitle tag="h5">
                            Résumé des paramètres de calcul
                            </CardTitle>
                            <CardText>Pas de résultats de simulation</CardText> 
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }
}
