import React, { Component } from 'react';
import { FormGroup, Label, Input, Button, Collapse, Col, Table, Card, CardBody, CardTitle } from 'reactstrap';
import { getIngredients } from '../../datafetchs/ingredients';
import { addRecipe, addIngredientsRecipe, addStepRecipe } from '../../datafetchs/recipes';

export default class RecipeAdd extends Component {
    constructor(props) {
        super(props);
    };

    state = {
        recipe: { isOneByFamily :false},
        recipeIngredients: [],
        recipeSteps: [],
        recipeStepsWithoutOrder:[],
        globalSettingsCollapse: true,
        recipeErrorGlobalSetting:"",
        ingredientCollapse: false,
        stepCollapse: false,
        buttonSubmitDisabled:true,
        inputSearchIngredient: "",
        listIngredientTodisplay: [],
        recipeIngredientsError: "",
        allIngredients: [],
        ingredientsListNotInRecipe: [],
        inputStep: { stepOrder: "", stepDescription: "" },
        maxStepOrder:0
    }

    componentDidMount() {
        getIngredients(this.props.auth.kc_token)
            .then(response => {
                if (response) {
                    this.setState({ allIngredients: response, listIngredientTodisplay: response, ingredientsListNotInRecipe:response })
                }
            }).catch (error => {
            console.error("There was an issue getting the ingredients:", error);
        });

    }
    handleChangeSwitch = (e) => {
        var recipe = this.state.recipe;
        if (recipe.isOneByFamily) {
            recipe.isOneByFamily = false;
        }
        else {
            recipe.isOneByFamily = true;
        }
        this.setState({ recipe: recipe });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            recipe: {
                ...prevState.recipe,
                [name]: value
            }
        }));
    }
    previousStep = () => {
        if (this.state.ingredientCollapse) {
            this.setState({ globalSettingsCollapse: true, ingredientCollapse: false, stepCollapse: false, recipeErrorGlobalSetting: "", recipeIngredientsError: "" })
        }
        else {
            this.setState({ globalSettingsCollapse: false, ingredientCollapse: true, stepCollapse: false, recipeErrorGlobalSetting: "", recipeIngredientsError: "" })

        }
    }
    nextStep = () => {
        if (this.state.globalSettingsCollapse) {
            var recipeName = this.state.recipe.name;
            var recipeVariant = this.state.recipe.variant;
            var recipeGlobalQuantity = this.state.recipe.globalQuantity;
            var numberRecipeGlobalQuantity = Number(recipeGlobalQuantity);
            if (recipeName === null || recipeName === undefined || recipeVariant === null || recipeVariant === undefined || recipeGlobalQuantity === null || recipeGlobalQuantity === undefined || isNaN(numberRecipeGlobalQuantity)) {
                this.setState({ recipeErrorGlobalSetting: "Merci de compléter les informations ; le nombre de personnes doit être un nombre", recipeIngredientsError:"" })
            }
            else {
                this.setState({ globalSettingsCollapse: false, ingredientCollapse: true, stepCollapse: false, recipeErrorGlobalSetting: "", recipeIngredientsError: "" })
            }
        }
        else if (this.state.ingredientCollapse) {
            var ingredientInRecipe = this.state.recipeIngredients;
            if (ingredientInRecipe.length === 0) {
                this.setState({ recipeErrorGlobalSetting: "", recipeIngredientsError: "Merci d'ajouter au moins un ingrédient" })
            }
            else {
                this.setState({ globalSettingsCollapse: false, ingredientCollapse: false, stepCollapse: true, recipeErrorGlobalSetting: "", recipeIngredientsError: "", buttonSubmitDisabled:false })
            }
        }
        else {
            this.setState({ globalSettingsCollapse: false, ingredientCollapse: false, stepCollapse: false })
        }
    }

    handleSearchIngredient = (e) => {
        this.setState({ inputSearchIngredient: e.target.value });

    }

    doSearch = () => {
        const data = this.state.ingredientsListNotInRecipe;
        var newData = [];
        for (var i = 0; i < data.length; i++) {
            var temp_Ingredients = data[i];
            var temp_name = temp_Ingredients.name;
            if (temp_name.includes(this.state.inputSearchIngredient)) {
                newData.push(data[i]);
            }
        }
        this.setState({
            listIngredientTodisplay: newData
        });
    }

    handleAddIngredientToRecipe = (ingredient,e) => {
        const allIngredients = this.state.allIngredients;
        var ingredientInRecipe = this.state.recipeIngredients;
        var quantity = e.target.parentNode.parentNode.children[2].children[0].children[0].children[0].value;
        var unitRef = e.target.parentNode.parentNode.children[2].children[0].children[1].children[0].value;
        var newIngredientInRecipeId = 1;
        
        var quantityFloat = parseFloat(quantity);
        if (quantity !== "" && isNaN(quantityFloat)===false &&unitRef!=="") {
            if (ingredientInRecipe.length !== 0) {
                newIngredientInRecipeId = ingredientInRecipe[ingredientInRecipe.length - 1].id+1;
            }
            var newIngredientObj = {};
            newIngredientObj.id = newIngredientInRecipeId;
            newIngredientObj.ingredient_id = ingredient.id;
            newIngredientObj.ingredient_name = ingredient.name;
            newIngredientObj.ingredient_variant = ingredient.variant;
            newIngredientObj.quantity = quantity;
            newIngredientObj.unitRef = unitRef;
            ingredientInRecipe.push(newIngredientObj);
            var ingredientToDisplay = this.getListofIngredientNotInRecipeList(ingredientInRecipe, allIngredients);
            this.setState({ listIngredientTodisplay: ingredientToDisplay, recipeIngredients: ingredientInRecipe });
        }
    }

    handleRemoveIngredient = (e, ingredientRecipe) => {
        const allIngredients = this.state.allIngredients;
        var ingredientsInRecipe = this.state.recipeIngredients;
        var newData = [];
        
        for (var i = 0; i < ingredientsInRecipe.length;i++) {
            var tempId = ingredientsInRecipe[i].id;
            if (tempId !== ingredientRecipe.id) {
                newData.push(ingredientsInRecipe[i]);
            }
        }
        var ingredientToDisplay = this.getListofIngredientNotInRecipeList(newData, allIngredients);
        this.setState({ listIngredientTodisplay: ingredientToDisplay, recipeIngredients: newData });

        

    }
    getListofIngredientNotInRecipeList = (ingredientsRecipe, allIngredients) => {
        var ingredientsListNotInRecipe = [];
        for (let i = 0; i < allIngredients.length; i++) {
            var tempObjIngredient = allIngredients[i];
            var ingredientId = tempObjIngredient.id;
            var booleanNoDetect = true;
            for (let compt = 0; compt < ingredientsRecipe.length; compt++) {
                var tempObjRecipeIngredient = ingredientsRecipe[compt];
                var recipeIngredientId = tempObjRecipeIngredient.ingredient_id;
                if (ingredientId === recipeIngredientId) {
                    booleanNoDetect = false;
                    compt = ingredientsRecipe.length;
                }
            }
            if (booleanNoDetect) {
                ingredientsListNotInRecipe.push(tempObjIngredient);
            }
        }
        return ingredientsListNotInRecipe;
    }

    handleCancel = () => {
        this.props.onCancel();

    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.recipe.name === undefined || this.state.recipe.variant === undefined || this.state.recipe.globalQuantity === undefined) {
            this.setState({
                globalSettingsCollapse: true,
                recipeErrorGlobalSetting: "Merci de renseigner les champs",
                ingredientCollapse: false,
                stepCollapse: false,
            });
        }
        else if (this.state.recipeIngredients.length === 0) {
            this.setState({
                globalSettingsCollapse: false,
                recipeErrorGlobalSetting: "",
                ingredientCollapse: true,
                stepCollapse: false,
                recipeIngredientsError: "Merci d'ajouter au moins un ingrédient" 
            });
        }
        else {
            addRecipe(this.props.auth.kc_token, this.state.recipe)
                .then((response) => {
                    var recipe_Id = response;
                    var ingredientsRecipe = this.state.recipeIngredients;
                    for (var i = 0; i < ingredientsRecipe.length; i++) {
                        var ingredientToAdd = {};
                        ingredientToAdd.recipeId = recipe_Id;
                        ingredientToAdd.ingredientId = ingredientsRecipe[i].ingredient_id;
                        ingredientToAdd.quantity = ingredientsRecipe[i].quantity;
                        ingredientToAdd.unitRef = ingredientsRecipe[i].unitRef;
                        addIngredientsRecipe(this.props.auth.kc_token, ingredientToAdd).then(()=> {  })
                    }
                    if (this.state.recipeSteps.length !== 0) {
                        var stepsInRecipe = this.state.recipeSteps;
                        for (var com = 0; com < stepsInRecipe.length; com++) {
                            var stepToAdd = {};
                            stepToAdd.recipeId = recipe_Id;
                            stepToAdd.stepOrder = stepsInRecipe[com].step_order;
                            stepToAdd.stepDescription = stepsInRecipe[com].step_description;
                            addStepRecipe(this.props.auth.kc_token, stepToAdd).then(() => { });
                            this.props.onSave();
                        }
                    }
                    else {

                        this.props.onSave();
                    }

                })
        }
    }
    handleChangeStep(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            inputStep: {
                ...prevState.inputStep,
                [name]: value
            }
        }));
    }
    addStepInRecipe =() =>{
        
        var stepsInRecipe = this.state.recipeSteps;
        var stepOrderToAdd = this.state.inputStep.stepOrder;
        var maxStepOrder = this.state.maxStepOrder;
        var recipeStepsWithoutOrder = this.state.recipeStepsWithoutOrder;
        
        var stepDescriptionToAdd = this.state.inputStep.stepDescription;

        const stepOrderToAddInt = parseInt(stepOrderToAdd);
        
        if (isNaN(stepOrderToAddInt) === false && stepOrderToAdd !== "") {
            var newObjRecipe = {};
            if (stepsInRecipe.length === 0) {
                const stepRecipeId = 1;
                newObjRecipe = { id: stepRecipeId, step_order: stepOrderToAdd, step_description: stepDescriptionToAdd };
                stepsInRecipe.push(newObjRecipe);
                recipeStepsWithoutOrder = stepsInRecipe;
                this.setState({ recipeSteps: stepsInRecipe, recipeStepsWithoutOrder: recipeStepsWithoutOrder, maxStepOrder:stepOrderToAdd });
            }
            else {
                var stepRecipeId = recipeStepsWithoutOrder[recipeStepsWithoutOrder.length-1].id+1;
                newObjRecipe = { id: stepRecipeId, step_order: stepOrderToAdd, step_description: stepDescriptionToAdd };
                stepsInRecipe.push(newObjRecipe);
                //recipeStepsWithoutOrder.push(newObjRecipe);
                if (stepOrderToAdd > maxStepOrder) {
                    maxStepOrder = stepOrderToAdd;
                    stepsInRecipe.sort(compareOrder);
                    
                    this.setState({ recipeSteps: stepsInRecipe, recipeStepsWithoutOrder: recipeStepsWithoutOrder, maxStepOrder: maxStepOrder });
                }
                else if (stepOrderToAdd === maxStepOrder) {
                    maxStepOrder = parseInt(maxStepOrder) + 1;
                    for (var i = 0; i < stepsInRecipe.length; i++) {
                        if (stepsInRecipe[i].step_order === stepOrderToAdd && stepsInRecipe[i].id !== stepRecipeId) {
                            stepsInRecipe[i].step_order = parseInt(stepsInRecipe[i].step_order) + 1;
                            
                        }
                        if (recipeStepsWithoutOrder[i].step_order === stepOrderToAdd && recipeStepsWithoutOrder[i].id !== stepRecipeId) {
                            recipeStepsWithoutOrder[i].step_order = parseInt(recipeStepsWithoutOrder[i].step_order) + 1;

                        }
                    }
                    
                    stepsInRecipe.sort(compareOrder);
                    
                    this.setState({ recipeSteps: stepsInRecipe, recipeStepsWithoutOrder: recipeStepsWithoutOrder, maxStepOrder: maxStepOrder });
                }
                else {
                    for (var i = 0; i < stepsInRecipe.length; i++) {
                        if (stepsInRecipe[i].step_order >= stepOrderToAdd && stepsInRecipe[i].id !== stepRecipeId) {
                            stepsInRecipe[i].step_order = parseInt(stepsInRecipe[i].step_order) + 1;
                        }
                        
                        if (recipeStepsWithoutOrder[i].step_order >= stepOrderToAdd && recipeStepsWithoutOrder[i].id !== stepRecipeId) {
                            recipeStepsWithoutOrder[i].step_order = parseInt(recipeStepsWithoutOrder[i].step_order) + 1;
                        }
                       
                    }
                    
                    stepsInRecipe.sort(compareOrder);
                    
                    this.setState({ recipeSteps: stepsInRecipe, recipeStepsWithoutOrder: recipeStepsWithoutOrder, maxStepOrder: maxStepOrder });

                }
                
                
            }
        }
        function compareOrder(a, b) {
            if (parseInt(a.step_order) - parseInt(b.step_order) < 0) {
                return -1;
            }
            else {
                return 1;
            }
        }
    }

    handleRemoveStep = (e, stepToRemove) => {
        
        var stepsInRecipe = this.state.recipeSteps;
        var recipeStepsWithoutOrder = this.state.recipeStepsWithoutOrder;
        var newData = [];
        var newDataNoOrder = [];

        for (var i = 0; i < stepsInRecipe.length; i++) {
            var tempId = stepsInRecipe[i].id;
            if (tempId !== stepToRemove.id) {
                newData.push(stepsInRecipe[i]);
            }
            if (recipeStepsWithoutOrder[i].id !== stepToRemove.id) {
                newDataNoOrder.push(recipeStepsWithoutOrder[i]);
            }
        }
        var recipeStepsInOrder = newData.sort(compareOrder)
        this.setState({ recipeSteps: recipeStepsInOrder, recipeStepsWithoutOrder: newDataNoOrder });
        function compareOrder(a, b) {
            if (parseInt(a.step_order) - parseInt(b.step_order) < 0) {
                return -1;
            }
            else {
                return 1;
            }
        }
    }
    handleKeyPress = (e) => {

        if (e.key === "Enter") {
            this.doSearch();
        }
    }

    render() {

        return (

            <>
                <Button disabled color="primary" style={{ width: "100%", marginBottom:"1rem"} }>
                    Paramètres généraux
                </Button>
                <Collapse isOpen={this.state.globalSettingsCollapse}>
                <FormGroup row>
                        <FormGroup>
                            <Label for="name">Nom</Label>
                            <Input type="text" name="name" id="name" value={this.state.recipe.name || ''} onChange={this.handleChange.bind(this)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="variant">Variante</Label>
                            <Input type="text" name="variant" id="variant" value={this.state.recipe.variant || ''} onChange={this.handleChange.bind(this)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="globalQuantity">Nombre de personnes</Label>
                            <Input type="number" name="globalQuantity" id="globalQuantity" value={this.state.recipe.globalQuantity} onChange={this.handleChange.bind(this)} />
                        </FormGroup>
                        <FormGroup switch>
                            <Label for="is_oneByFamily">
                                Une par famille (mithan ou kharass)?*
                            </Label>
                            <Input id="is_oneByFamily" name="is_oneByFamily" type="switch" checked={this.state.recipe.isOneByFamily} onClick={this.handleChangeSwitch.bind(this)} onChange={e => {}}>
                            </Input>
                        </FormGroup>
                        <div style={{color:"red"} }>{this.state.recipeErrorGlobalSetting}</div>
                        <Button onClick={this.nextStep} style={{ width: "auto", color: "white", marginLeft: "auto" } }>Suivant</Button>
                    </FormGroup>
                </Collapse>
                <Button disabled color="primary" style={{ width: "100%", marginBottom: "1rem"}}>
                    Ingrédient de la recette
                </Button>
                <Collapse isOpen={this.state.ingredientCollapse}>
                    <Card name="card" style={{ width: 'auto', height: "400px", overflow: "scroll", marginTop: "10px" }}>
                        <CardBody>

                            <CardTitle tag="h5">Ingrédients de la recette</CardTitle>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nom de l'ingrédient</th>
                                        <th>Variante</th>
                                        <th>Quantité</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.recipeIngredients.map(ingredientRecipe => (
                                        <tr key={ingredientRecipe.id}>
                                            <td>{ingredientRecipe.ingredient_name}</td>
                                            <td>{ingredientRecipe.ingredient_variant}</td>
                                            <td>{ingredientRecipe.quantity}  {ingredientRecipe.unitRef}</td>
                                            <td>
                                                <Button color="danger" onClick={(e) => this.handleRemoveIngredient(e, ingredientRecipe)}>Retirer l'ingrédient</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <Card name="card" style={{ width: 'auto', height: "400px", overflow: "scroll", marginTop: "10px" }}>
                        <CardBody>
                            <CardTitle tag="h5">Ingrédients disponibles à l'ajout</CardTitle>
                            <FormGroup row>
                                <Label for="searchIngredient" sm={2}>Rechercher votre ingrédient</Label>
                                <Col sm={8}>
                                    <Input type="search" name="searchIngredient" id="searchIngredient" value={this.state.inputSearchIngredient} onChange={this.handleSearchIngredient.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} />
                                </Col>
                                <Col sm={2}>
                                    <Button color="primary" onClick={this.doSearch}>Rechercher</Button>
                                </Col>
                            </FormGroup>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nom de l'ingrédient</th>
                                        <th>Variante</th>
                                        <th>Quantité pour {this.state.recipe.globalQuantity} personnes</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listIngredientTodisplay.map(ingredient => (
                                        <tr key={ingredient.id}>
                                            <td>{ingredient.name}</td>
                                            <td>{ingredient.variant}</td>
                                            <td>
                                                <FormGroup row>
                                                    <Col sm={5}><Input type="text" name="ingredient_quantity" id="ingredient_quantity" onChange={this.handleChange.bind(this)} /></Col>
                                                    <Col sm={3}><Input type="select" name="primaryunit" id="primaryunit" onChange={this.handleChange.bind(this)}>
                                                        <option>{ingredient.primaryunit}</option>
                                                        <option>
                                                            {(ingredient.estimationunit && ingredient.estimationunit !== "0" && ingredient.primaryunit !== "Unité") && "Unité"}
                                                        </option>
                                                    </Input>
                                                    </Col>
                                                </FormGroup>
                                            </td>
                                            <td><Button onClick={(e) => { this.handleAddIngredientToRecipe(ingredient,e) }}>Ajouter</Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <div style={{ color: "red" }}>{this.state.recipeIngredientsError}</div>
                    <Button onClick={this.nextStep} style={{ width: "auto", color: "white", marginTop: "1rem", marginRight: "2rem", marginBottom: "1rem" }}>Suivant</Button>
                    <Button onClick={this.previousStep} style={{ width: "auto", color: "white", marginTop: "1rem", marginRight: "2rem", marginBottom: "1rem" }}>Précédent</Button>
                </Collapse>
                <Button disabled color="primary" style={{ width: "100%", marginBottom: "1rem" }}>
                    Etapes de la recette (facultatif)
                </Button>
                <Collapse isOpen={this.state.stepCollapse}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Etapes</th>
                                <th>Description de l'étape</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.recipeSteps.map(stepRecipe => (
                                <tr key={stepRecipe.id}>
                                    <td>Etape {stepRecipe.step_order}</td>
                                    <td>{stepRecipe.step_description}</td>
                                    <td>
                                        <Button color="danger" onClick={(e) => this.handleRemoveStep(e, stepRecipe)}>Retirer l'étape de la recette</Button>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </Table>
                    <FormGroup>
                        <FormGroup>
                            <Label for="stepOrder">Numéro d'étape</Label>
                            <Input type="number" name="stepOrder" id="stepOrder" value={this.state.inputStep.stepOrder} onChange={(e) => { this.handleChangeStep(e) }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="stepDescription">Description de l'étape</Label>
                            <Input type="textarea" name="stepDescription" id="stepDescription" value={this.state.inputStep.stepDescription} onChange={(e) => { this.handleChangeStep(e) }} />
                        </FormGroup>
                        <Button onClick={this.addStepInRecipe}>Ajouter l'étape</Button>
                    </FormGroup>
                    <Button onClick={this.previousStep} style={{ width: "auto", color: "white", marginTop: "1rem", marginRight: "2rem", marginBottom: "1rem" }}>Précédent</Button>   
                </Collapse>
                <Button color="sucess" onClick={(recipe) => { this.handleSubmit(recipe) }} disabled={this.state.buttonSubmitDisabled }>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
                
            </>

        );
    };
}
