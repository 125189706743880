import React, { Component } from 'react';
import { Button, FormGroup, Input, Label, Col } from 'reactstrap';
import RecipeList from './RecipeList';
import RecipeAdd from './RecipeAdd';
import RecipeViewIngredients from './RecipeViewIngredients';
import RecipeViewSteps from './RecipeViewSteps';
import RecipeEditGlobal from './RecipeEditGlobal';
import { getRecipe } from '../../datafetchs/recipes';
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import RefreshProvider from "../auth/RefreshProvider";


class RecipeManagementInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewRecipeIngredients: null,
            viewRecipeSteps: null,
            editRecipe:null,
            addingRecipe: false,
            recipesFiltering: [],
            allRecipes: [],
            inputSearch: ''
        };
    }

    handleEditGlobalRecipe = (recipe) => {
        this.setState({ editRecipe: recipe });
    }

    handleViewRecipeIngredient = (recipe) => {
        this.setState({ viewRecipeIngredients: recipe });
    }

    handleViewStepsRecipe = (recipe) => {
        this.setState({viewRecipeSteps: recipe });
    }

    handleSave = () => {
        // Once the ingredient is saved or added, revert back to the list
        
        this.setState({ viewRecipeIngredients: null, viewRecipeSteps: null, editRecipe: null, addingRecipe: false });
        this.getAllRecipes();
    }
    handleCancel = () => {
        // Once the ingredient is saved or added, revert back to the list
        this.setState({ viewRecipeIngredients: null, viewRecipeSteps: null, editRecipe: null, addingRecipe: false });
    }

    handleAddClick = () => {
        this.setState({ addingRecipe: true });
    }

    handleChange = (e) => {
        this.setState({ inputSearch: e.target.value });

    }
    componentDidMount() {
        this.getAllRecipes();
    }
    handleKeyPress = (e) => {
        
        if (e.key === "Enter") {
            this.doSearch();
        }
    }
    getAllRecipes = () => {
        getRecipe(this.props.auth.kc_token)
            .then(response => {
                if (response) {
                    this.setState({ recipesFiltering: response, allRecipes: response });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the recipe:", error);
            });
    }

    doSearch = () => {
        var allRecipes = this.state.allRecipes;
        var arg = this.state.inputSearch;
        var newData = [];
        if (arg === null || arg === "" || arg === undefined) {
            this.getAllRecipes();
        } else {
            for (var i = 0; i < allRecipes.length; i++) {
                var temp_Recipe = allRecipes[i];
                var temp_name = temp_Recipe.name;
                if (temp_name.toUpperCase().includes(arg.toUpperCase())) {
                    newData.push(allRecipes[i]);
                }
            }
            this.setState({ recipesFiltering: newData });
        }
    }

    render() {
        
        if (this.state.editRecipe) {
            return <RecipeEditGlobal auth={this.props.auth} recipe={this.state.editRecipe} onSave={this.handleSave} onCancel={this.handleCancel} />;
        }
        if (this.state.viewRecipeIngredients) {
            return <RecipeViewIngredients auth={this.props.auth} recipe={this.state.viewRecipeIngredients} onSave={this.handleSave} onCancel={this.handleCancel} />;
        }

        if (this.state.viewRecipeSteps) {
            return <RecipeViewSteps auth={this.props.auth} recipe={this.state.viewRecipeSteps} onSave={this.handleSave} onCancel={this.handleCancel} />;
        }

        if (this.state.addingRecipe) {
            return <RecipeAdd auth={this.props.auth} onSave={this.handleSave} onCancel={this.handleCancel} />;
        }


        return (
            <>
                <FormGroup row>
                    <Label for="searchRecipe" sm={2}>Rechercher votre recette</Label>
                    <Col sm={8}>
                        <Input type="search" name="searchRecipe" id="searchRecipe" value={this.state.inputSearch} onChange={this.handleChange.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} />
                    </Col>
                    <Col sm={2}>
                        <Button color="primary" onClick={this.doSearch}>Rechercher</Button>
                    </Col>
                </FormGroup>
                <RecipeList auth={this.props.auth} recipesFiltering={this.state.recipesFiltering} onViewRecipeIngredient={this.handleViewRecipeIngredient} onViewStepsRecipe={this.handleViewStepsRecipe} editGlobalRecipe={this.handleEditGlobalRecipe} onSave={this.handleSave} />
                <Button color="primary" onClick={this.handleAddClick}>Ajouter une nouvelle recette</Button>
            </>
        );
    }
}

const RecipeManagement = () => {
    console.debug("Entering component=RecipeManagement")
    const authState = useSelector((state) => state.auth);
    if ( (!authState.authenticated) && (!authState.kc_roles.includes("AdminRole")) ) {
        return <Navigate to="/home" />
    }
    return (
        <RefreshProvider>
            <RecipeManagementInner auth={authState}/>
        </RefreshProvider>
    );
};

export default RecipeManagement;
