import React, { Component } from 'react';
import { Button, FormGroup, Input, Label, Col } from 'reactstrap';
import StockList from './StockList'
import StockEdition from './StockEdition'
import StockEstimation from './StockEstimation'
import { getIngredients } from '../../datafetchs/ingredients';
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import RefreshProvider from "../auth/RefreshProvider";

export class StockManagementInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingStock: false,
            estimateStock: false,
            ingredientFiltering: [],
            inputSearch: '',
            selectSearch: "Nom"
        };
    }

    handleEdit = () => {
        this.setState({ editingStock: true });
    }
    handleCancelState = () => {
        this.setState({ editingStock: false, estimateStock: false, searchField: "" });
    }
    handleSave = () => {
        // Once the ingredient is saved or added, revert back to the list
        this.getAllIngredients();
        this.setState({ editingStock: false, estimateStock: false, searchField:""});
    }

    
    handleEstimationClick = () => {
        this.setState({ estimateStock: true });
    }
    handleChange = (e) => {
        if (e.target.name === "searchIngredient") {
            this.setState({ inputSearch: e.target.value });
        }
        if (e.target.name === "selectCriteria") {
            this.setState({ selectSearch: e.target.value });
        }

    }
    componentDidMount() {
        this.getAllIngredients();
    }
    getAllIngredients = () => {
        getIngredients(this.props.auth.kc_token)
            .then(response => {
                if (response) {
                    var allIngredientsStock = [];
                    for (var i = 0; i < response.length; i++) {
                        var temp_obj = response[i];
                        if (temp_obj.stock === 0) {
                            temp_obj.stateStock = "Pas en stock";
                            temp_obj.stateColor = "red";
                        }
                        else if (temp_obj.stock < temp_obj.limitstock) {
                            temp_obj.stateStock = "Stock limité";
                            temp_obj.stateColor = "orange";
                        }
                        else {
                            temp_obj.stateStock = "Stock suffisant";
                            temp_obj.stateColor = "green";

                        }
                        temp_obj.disableEditSaveButton = true;
                        allIngredientsStock.push(temp_obj);
                    }
                    this.setState({ allIngredients: allIngredientsStock, ingredientFiltering: allIngredientsStock });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the ingredients:", error);
            });
    }

    doSearch = () => {
        var data = this.state.allIngredients;
        var ingredientAfterFiltering = [];
        var searchField = this.state.inputSearch;
        var temp_Ingredients = {};
        if (this.state.selectSearch === "Nom") {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];

                var temp_name = temp_Ingredients.name;
                if (temp_name.toUpperCase().includes(searchField.toUpperCase())) {
                    ingredientAfterFiltering.push(data[i]);
                }
            }
        }
        else if (this.state.selectSearch === "Catégorie") {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];

                var temp_category = temp_Ingredients.category;
                if (temp_category !== null) {
                    if (temp_category.toUpperCase().includes(searchField.toUpperCase())) {
                        ingredientAfterFiltering.push(data[i]);
                    }
                }
            }
        }
        else if (this.state.selectSearch === "Etat du stock") {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];

                var temp_stateStock = temp_Ingredients.stateStock;
                if (temp_stateStock.toUpperCase().includes(searchField.toUpperCase())) {
                        ingredientAfterFiltering.push(data[i]);
                }     
            }
        }
        else {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];

                var temp_supplier = temp_Ingredients.supplier;
                if (temp_supplier !== null) {
                    if (temp_supplier.toUpperCase().includes(searchField.toUpperCase())) {
                        ingredientAfterFiltering.push(data[i]);
                    }
                }
            }

        }
        this.setState({ ingredientFiltering: ingredientAfterFiltering });
    }

    handleKeyPress = (e) => {

        if (e.key === "Enter") {
            this.doSearch();
        }
    }

    render() {
        if (this.state.estimateStock) {
            return <StockEstimation auth={this.props.auth} onSave={this.handleSave} onCancel={this.handleCancelState} ingredientsList={this.state.allIngredients} />;
        }
        if (this.state.editingStock) {
            return (
                <>
                    <FormGroup row>
                        <Label for="searchIngredient" sm={1.5}>Rechercher par</Label>
                        <Col sm={2}>
                            <Input type="select" name="selectCriteria" id="selectCriteria" value={this.state.selectSearch} onChange={this.handleChange.bind(this)} >
                                <option>Nom</option>
                                <option>Catégorie</option>
                                <option>Fournisseur</option>
                                <option>Etat du stock</option>
                            </Input>
                        </Col>
                        <Col sm={6}>
                            <Input type="search" name="searchIngredient" id="searchIngredient" value={this.state.inputSearch} onChange={this.handleChange.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} placeholder="Paramètre de recherche" />
                        </Col>
                        <Col sm={2}>
                            <Button color="primary" onClick={this.doSearch}>Rechercher</Button>
                        </Col>
                    </FormGroup>
                    <StockEdition auth={this.props.auth} ingredientFiltering={this.state.ingredientFiltering} onEdit={this.handleEdit} onSave={this.handleSave} handleCancelState={this.handleCancelState} />
                </>
            );
        }


        return (
            <>
                <FormGroup row>
                    <Label for="searchIngredient" sm={1.5}>Rechercher par</Label>
                    <Col sm={2}>
                        <Input type="select" name="selectCriteria" id="selectCriteria" value={this.state.selectSearch} onChange={this.handleChange.bind(this)} >
                            <option>Nom</option>
                            <option>Catégorie</option>
                            <option>Fournisseur</option>
                            <option>Etat du stock</option>
                        </Input>
                    </Col>
                    <Col sm={6}>
                        <Input type="search" name="searchIngredient" id="searchIngredient" value={this.state.inputSearch} onChange={this.handleChange.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} placeholder="Paramètre de recherche" />
                    </Col>
                    <Col sm={2}>
                        <Button color="primary" onClick={this.doSearch}>Rechercher</Button>
                    </Col>
                </FormGroup>
                <StockList auth={this.props.auth} ingredientFiltering={this.state.ingredientFiltering} onSave={this.handleSave} />
                <Button color="primary" onClick={this.handleEdit} style={{ marginRight: "5px" }}>Inventaire</Button>
                <Button onClick={this.handleEstimationClick}>Estimer le stock</Button>
            </>
        );
    }
    
}

const StockManagement = () => {
    const authState = useSelector((state) => state.auth);
    if ((!authState.authenticated) && (!authState.kc_roles.includes("AdminRole"))) {
        return <Navigate to="/" />
    }
    return (
        <RefreshProvider>
            <StockManagementInner auth={authState} />
        </RefreshProvider>
    );
};

export default StockManagement;