import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {administrationFieldsUrl} from "../../datafetchs/datafetchUrls";
import {getExecReq} from "../../datafetchs/common";

const initialState = {
    administrationFields: {},
    loaded: false,
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAdministrationFields: (state, action) => {
            state.administrationFields = action.payload;
            state.loaded = true;
        },
        reInitState: () => {
            return initialState;
        }
    }
});

export const getAdminFields = createAsyncThunk(
    'admin/getFields',
    async (token, { dispatch }) => {
        try {
            console.debug("Entering thunk=admin/getFields");
            const response = await getExecReq(administrationFieldsUrl, token);
            const responsejson = await response.json();
            console.debug(responsejson);
            const localAdminFields = responsejson[0];
            dispatch(setAdministrationFields(localAdminFields));
        } catch (error) {
            console.error("Adminfields initialization failed:", error);
        }
    }
);

export const {
    setAdministrationFields,
    reInitState,
} = adminSlice.actions;
export default adminSlice.reducer;
