import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../components/auth/authSlice'
import adminReducer from '../components/administration/adminSlice'
// https://www.blazemeter.com/blog/import-lodash-libraries
import _ from "lodash";

// https://react-redux.js.org/tutorials/quick-start

const stateKey = "abh01_state"

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(stateKey);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    console.debug("Entering function=saveState")
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateKey, serializedState);
    } catch {
        // ignore write errors
    }
};

const store = configureStore({
    // devTools: true,
    reducer: {
        auth: authReducer,
        admin: adminReducer,
    },
    preloadedState: loadState()
});

// store.subscribe(() => {
//     saveState(store.getState());
// });

store.subscribe(_.throttle(() => { saveState(store.getState()) }, 1000));

export default store;