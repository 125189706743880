import React, {Component, useEffect} from 'react';
import {Button, Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import './NavMenu.css';
import { useDispatch, useSelector } from "react-redux";
import { registerSSO, login, logout } from "./auth/authSlice";

class NavMenuAdmin extends Component {
  static displayName = NavMenuAdmin.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      authenticated: this.props.auth.authenticated,
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return (
          <header style={{ backgroundColor: "#A4834E" }}>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">Mawaid</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul style={{ color: "#FEF631" }} className="navbar-nav flex-grow" >
              <NavItem>
                <NavLink tag={Link} className="text-dark"  style={{ fontWeight: "bold" }} to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/administrator">Administration</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/knowledgeHalalHaram">Composants halal/haram</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/calculator">Calculateur</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/recipes">Recettes</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/ingredients">Ingrédients</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/stock">Gestion de stock</NavLink>
              </NavItem>
              <Button onClick={this.props.dispatchEv}>
                {this.state.authenticated ? "Se déconnecter":"Se connecter"}
              </Button>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

class NavMenuCuisinier extends Component {
  static displayName = NavMenuCuisinier.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      authenticated: this.props.auth.authenticated,
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return (
          <header style={{ backgroundColor: "#A4834E" }}>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">Mawaid</NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul style={{ color: "#FEF631" }} className="navbar-nav flex-grow" >
              <NavItem>
                <NavLink tag={Link} className="text-dark"  style={{ fontWeight: "bold" }} to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/knowledgeHalalHaram">Composants halal/haram</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/recipes">Recettes</NavLink>
              </NavItem>
              <Button onClick={this.props.dispatchEv}>
                {this.state.authenticated ? "Se déconnecter":"Se connecter"}
              </Button>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );

  }
}

class NavMenuBasic extends Component {
  static displayName = NavMenuBasic.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      authenticated: this.props.auth.authenticated,
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    console.debug("Entering component NavMenuBasic")
    return (
        <header style={{ backgroundColor: "#A4834E" }}>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
            <NavbarBrand tag={Link} to="/">Mawaid</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul style={{ color: "#FEF631" }} className="navbar-nav flex-grow" >
                <NavItem>
                  <NavLink tag={Link} className="text-dark"  style={{ fontWeight: "bold" }} to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/knowledgeHalalHaram">Composants halal/haram</NavLink>
                </NavItem>
                <Button onClick={this.props.dispatchEv}>
                  {this.state.authenticated ? "Se déconnecter":"Se connecter"}
                </Button>
              </ul>
            </Collapse>
          </Navbar>
        </header>
    );
  }
}

const NavMenu = () => {
  console.debug("Entering component=NavMenu")
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  
  function dispatchLoginLogout() {
      console.debug("Entering method=dispatchLoginLogout");
      if (!authState.authenticated) {
        dispatch(login());
      } else {
        dispatch(logout());
      }
  }
  
  useEffect(() => {
    dispatch(registerSSO());
  }, [dispatch]);
  
  if (authState.authenticated) {
    if (authState.kc_roles.includes("AdminRole")) {
      return <NavMenuAdmin auth={authState} dispatchEv={dispatchLoginLogout} />
    } else if (authState.kc_roles.includes("CuisinierRole")) {
      return <NavMenuCuisinier auth={authState} dispatchEv={dispatchLoginLogout} />
    } else {
      return <NavMenuBasic auth={authState} dispatchEv={dispatchLoginLogout} />
    }
  } else {
    return <NavMenuBasic auth={authState} dispatchEv={dispatchLoginLogout} />
  }
};

export default NavMenu;
