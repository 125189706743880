import React, { Component } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import { addStepRecipe } from '../../datafetchs/recipes';

export default class RecipeAddStep extends Component {
    constructor(props) {
        super(props);
       


    };
    state = {
        recipe: this.props.recipe || {},
        stepOrderToAdd: this.props.lastStep+1,
        stepDescriptionToAdd:""
    }

    handleChangeOrder(e) {
        const value = e.target.value;
        this.setState({
            stepOrderToAdd: value
        }
        );
    }

    handleChangeDescription(e) {
        const value = e.target.value;
        this.setState({
            stepDescriptionToAdd: value
        }
        );
    }

    handleSubmit = () => {
        let stepInRecipe = {};
        stepInRecipe.stepOrder = this.state.stepOrderToAdd;
        stepInRecipe.stepDescription = this.state.stepDescriptionToAdd;
        stepInRecipe.recipeId = this.state.recipe.id;
        addStepRecipe(this.props.auth.kc_token, stepInRecipe).then(() => {
            this.props.onSave();
        })
    }

    handleCancel = () => {
        this.props.onCancel();

    }

    
    render() {
        
        return (

            <>
                <FormGroup row>
                    <FormGroup>
                        <Label for="stepOrder">Numéro d'étape</Label>
                        <Input type="number" name="stepOrder" id="stepOrder" value={this.state.stepOrderToAdd || ''} onChange={this.handleChangeOrder.bind(this)} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="stepDescription">Description de l'étape</Label>
                        <Input type="textarea" name="stepDescription" id="stepDescription" value={this.state.stepDescriptionToAdd || ''} onChange={this.handleChangeDescription.bind(this)} />
                    </FormGroup>
                </FormGroup>
                <Button color="sucess" type="submit" onClick={this.handleSubmit}>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
            </>

        );
    };

}