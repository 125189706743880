
import React, {Component} from 'react';
import { Nav, NavItem,Button } from 'reactstrap';
import AdministrationCalculator from './AdministrationCalculator';
import {useSelector } from "react-redux";
import {Navigate} from "react-router-dom";
import RefreshProvider from "../auth/RefreshProvider";

class AdministrationManagementInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuSelected: "calculatorParameters",
        };
    }
    handleOnclick(e) {
        const menuSelected = e.target.value;
        this.setState({ menuSelected: menuSelected });
    }
    
    render() {
        if (this.state.menuSelected === "calculatorParameters") {
            return (
                <>
                    <Nav tabs>
                        <NavItem >
                            <Button onClick={(e) => this.handleOnclick(e)} style={{ backgroundColor: "white", color: "black" }} value="calculatorParameters">Paramètres du calculateur</Button>
                        </NavItem>
                    </Nav>
                    <AdministrationCalculator auth={this.props.auth} />
                </>
            );
        }
    }
}

const AdministrationManagement = () => {
    const authState = useSelector((state) => state.auth);
    if ( (!authState.authenticated) && (!authState.kc_roles.includes("AdminRole")) ) {
        return <Navigate to="/home" />
    }
    return (
        <RefreshProvider>
            <AdministrationManagementInner auth={authState} />
        </RefreshProvider> 
    );
};

export default AdministrationManagement;
