import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getKeycloakInstance, getKeycloakInstanceEffect} from "./keycloak";

const initialState = {
    authenticated: false,
    kc_token: "",
    kc_refreshToken: "",
    kc_idToken: "",
    kc_roles: [],
    last_refresh: "",
    last_check: "",
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: { 
        setAuth: (state, action) => {
            console.debug("Entering reducer=setAuth")
            state.authenticated = true;
            state.kc_token = action.payload.token;
            state.kc_refreshToken = action.payload.refreshToken;
            state.kc_idToken = action.payload.idToken;
            state.kc_roles = action.payload.roles;
            state.last_check = Date.now();
            state.last_refresh = Date.now();
        },
        initState: () => {
            console.debug("Entering reducer=initState")
            localStorage.clear();
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                // Handle any post-initialization success
            })
            .addCase(refresh.fulfilled, (state, action) => {
                // Handle any post-refresh success
            });
    }
});

export const login = createAsyncThunk(
    'auth/login',
    async (_, { dispatch }) => {
        console.debug("Entering thunk=auth/login")
        const keycloak = getKeycloakInstance();
        await keycloak.init({ onLoad: 'login-required' });
    }
);

export const registerSSO = createAsyncThunk(
  'auth/registerSSO',
  async (_, { getState, dispatch }) => {
      console.debug("Entering thunk=auth/registerSSO")
      const keycloak = getKeycloakInstance();
      const state = getState();
      try {
          if (!state.auth.authenticated) {
              const isKeycloakInit = await keycloak.init({onLoad: 'check-sso'});
              if (isKeycloakInit) {
                  const localAuthState = {
                      token: keycloak.token,
                      refreshToken: keycloak.refreshToken,
                      idToken: keycloak.idToken,
                      roles: keycloak.realmAccess.roles,
                  };
                  dispatch(setAuth(localAuthState));
              }
          }
      } catch (error) {
          console.error("Auth state sync failed:", error);
      }
  }
);

export const refresh = createAsyncThunk(
    'auth/refresh',
    async (_, { getState, dispatch }) => {
        console.debug("Entering thunk=auth/refresh")
        const state = getState()
        const keycloak= await getKeycloakInstanceEffect(state.auth);
        if (keycloak.isTokenExpired()) {
            try {
                const resp = await keycloak.updateToken(60);
                if (!resp) {
                    dispatch(logout());
                } else {
                    const localAuthState = {
                        token: keycloak.token,
                        refreshToken: keycloak.refreshToken,
                        idToken: keycloak.idToken,
                        roles: keycloak.realmAccess.roles,
                    };
                    dispatch(setAuth(localAuthState));
                }
            } catch (error) {
                error("Token refresh failed:", error);
            }
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, { getState, dispatch }) => {
        console.debug("Entering thunk=auth/logout");
        const state = getState();

        if (!state.auth.authenticated) {
            return;
        }

        try {
            const keycloak = await getKeycloakInstanceEffect(state.auth);
            if (keycloak) {
                dispatch(initState());
                await keycloak.logout();
            }
        } catch (error) {
            console.error("Logout failed:", error);
        }
    }
);

export const {
    setAuth,
    initState,
} = authSlice.actions;
export default authSlice.reducer;
