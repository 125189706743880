import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {isRefreshNeeded} from "./keycloak";
import {Navigate} from "react-router-dom";
import {logout} from "./authSlice";

export const RefreshProvider = ({ children }) => {
    console.debug("Entering component=RefreshProvider")
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);

    // https://stackoverflow.com/questions/53945763/
    useEffect(() => {
        if (isRefreshNeeded(authState.last_refresh, authState.last_check)) {
            dispatch(logout());
        }
    }, []);

    if (!authState.authenticated) {
        return <Navigate to="/" />;
    } 

    return <>{children}</>;
};

export default RefreshProvider;
