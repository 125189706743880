import Keycloak from 'keycloak-js';

/**
 * Checks if given timestamp has an inconsistent format.
 *
 * @param {number||string} inTimestamp - The timestamp to validate 
 * @returns {boolean} - Returns true if timestamp is valid.
 */
export function isTimestampValid(inTimestamp) {
    let ret = true;
    
    const parsedInTimestamp = parseInt(inTimestamp);
    const isValidInTimestamp = (
        (!Number.isNaN(parsedInTimestamp))
        &&
        (parsedInTimestamp > 0)
        && 
        (parsedInTimestamp <= Date.now())
    );
    
    if (!isValidInTimestamp) {
        ret = false;
    }
    return ret;
}

/**
 * Checks if refresh is needed based on the last refreshed and last checked times.
 *
 * @param {number} lastRefreshed - The timestamp of the last refresh.
 * @param {number} lastChecked - The timestamp of the last check.
 * @param {number} [refreshInterval=60*60*1000] - The interval in milliseconds to consider for refreshing. Defaults to 1 hour.
 * @param {number} [checkInterval=60*60*1000] - The interval in milliseconds to consider for rechecking. Defaults to 1 hour.
 * @returns {boolean} - True if refresh is needed based on the given intervals.
 */
export function isRefreshNeeded(lastRefreshed, lastChecked, refreshInterval = 40 * 60 * 1000, checkInterval = 50 * 60 * 1000) {
    console.debug("Entering function=isRefreshNeeded")
    const currentTime = Date.now();
    let needsRefresh = true;

    if (isTimestampValid(lastRefreshed) && isTimestampValid(lastChecked)) {
        const isRefreshDue = (currentTime - lastRefreshed) >= refreshInterval;
        const isCheckDue = (currentTime - lastChecked) >= checkInterval;

        if (!(isRefreshDue || isCheckDue)) {
            needsRefresh = false;
        }
    }
    return needsRefresh;
}

export const getKeycloakInstance = () => {
    const keycloakConfig = {
        url: 'https://auth.jamaat.fr/',
        realm: 'ABL01',
        clientId: 'abh01_frontend',
        onLoad: 'login-required'
    };
    return new Keycloak(keycloakConfig);
};


export const getKeycloakInstanceEffect = async (state) => {
    let ret = getKeycloakInstance();
    if (state.authenticated) {
        const initk = await ret.init({
            onLoad: 'check-sso',
            token: state.kc_token,
            refreshToken: state.kc_refreshToken,
            idToken: state.kc_idToken
        });
        if (!initk) {
            console.debug("Could not init keycloak object with current state.");
        }
    }
    return ret;
};


export const isKeycloakTokenExpired = async (state) => {
    let ret = getKeycloakInstance();
    if (state.authenticated) {
        await ret.init({
            onLoad: 'check-sso',
            token: state.kc_token,
            refreshToken: state.kc_refreshToken,
            idToken: state.kc_idToken
        });
    }
    return ret.isTokenExpired();
};

