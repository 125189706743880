
import React, {Component, useEffect} from 'react';
import { Table, Button,Input } from 'reactstrap';
import {updateAdministrationFields } from '../../datafetchs/administrationFields';
import {useDispatch, useSelector} from "react-redux";
import {getAdminFields} from "./adminSlice";

class AdministrationCalculatorInner extends Component {
    constructor(props) {
        super(props);
        const administrationFields = JSON.parse(JSON.stringify(this.props.fields.administrationFields));
        this.state = {
            administrationFields,
            disableButton: true,
            errorMessage:"",
        };
        console.debug("Entering component=AdministrationCalculatorInner");
    }
    
    handleNumberChange = (e) => {
        var administrationFields = this.state.administrationFields;
        if (e.target.name === "thaalis") {
            administrationFields.thalisParameters = e.target.value;
        }
        if (e.target.name === "family") {
            administrationFields.familyParameter = e.target.value;
        }
        if (e.target.name === "ratio") {
           
            administrationFields.ratio = e.target.value;
        }
        e.preventDefault();
        this.setState({ administrationFields: administrationFields, disableButton:false });

    }
    
    saveChange = () => {

        updateAdministrationFields(this.props.auth.kc_token, this.state.administrationFields).then((response) => {
            if (response.message === "Administration fields updated successfully.") {
                this.props.dispatch(getAdminFields(this.props.auth.kc_token));
                this.setState({errorMessage: ""});
            }
            else {
                this.setState({ errorMessage: "Merci de vérifier que la ratio est renseigné avec un . et non une virgule" });
            }
            
        })
            .catch(error => {
                this.setState({ errorMessage: "Merci de vérifier que la ratio est renseigné avec un . et non une virgule" });
                console.error("There was an issue updating the parameters:", error);
            }); 
        
    }

    render() {
        return (
            <>
                <Table>
                    <thead>
                        <tr>
                            <th>Nom du paramètres</th>
                            <th>Valeurs</th>
                        </tr>
                    </thead>
                    <tbody>
                            <tr key="1">
                                <td>Paramètre de calcul de thaalis</td>
                                <td>    
                                    <Input type="number" name="thaalis" id="thaalis" placeholder="Nbres de personnes" value={this.state.administrationFields.thalisParameters} onChange={this.handleNumberChange.bind(this)} />
                                     personnes/thaalis
                                </td>
                            </tr>
                            <tr key="2">
                                <td>Paramètre de calcul de famille</td>
                                <td>
                                <Input type="number" name="family" id="family" placeholder="Nbres de personnes" value={this.state.administrationFields.familyParameter} onChange={this.handleNumberChange.bind(this)} />
                                 personnes/famille</td>
                            </tr>
                            <tr key="3">
                                <td>Ratio de calcul (prix)</td>
                                <td>
                                    <Input type="text" name="ratio" id="ratio" value={this.state.administrationFields.ratio} onChange={this.handleNumberChange.bind(this)} />
                                    personnes/famille</td>
                            </tr>
                    </tbody>
                </Table>
                <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                <Button disabled={this.state.disableButton} onClick={this.saveChange }>Sauvegarder</Button>
            </>
        );
    }
}


const AdministrationCalculator = (props) => {
    const dispatch = useDispatch();
    const fieldsState = useSelector( (state) => state.admin);
    console.debug("Entering component=AdministrationCalculator");

    useEffect(() => {
        dispatch(getAdminFields(props.auth.kc_token));
    }, []);

    return (
        <AdministrationCalculatorInner auth={props.auth.kc_token} fields={fieldsState} dispatch={dispatch}/>
    );
};

export default AdministrationCalculator;
