import React, { Component } from 'react';
import { Card, CardBody, Col, Table, FormGroup, Label,Input, CardText, CardSubtitle, CardTitle, Button } from 'reactstrap';
import { getIngredientsRecipe } from '../../datafetchs/recipes';
import RecipeModifyIngredients from './RecipeModifyIngredients';
import { getIngredients } from '../../datafetchs/ingredients';

export default class RecipeViewIngredients extends Component {
    constructor(props) {
        super(props);
        console.debug("Entering component=RecipeViewIngredients");
    };
    state = {
        recipe: this.props.recipe || {},
        ingredientsRecipe: [],
        ingredientsListNotInRecipe: [],
        allIngredients:[],
        inputSearch: this.props.recipe.globalQuantity,
        modifyIngredientsinRecipe:false
    };

    handleChange = (e) => {
        this.setState({ inputSearch: e.target.value });      
    }

    getListofIngredientNotInRecipeList = (ingredientsRecipe, allIngredients) => {
        var ingredientsListNotInRecipe = [];
        for (let i = 0; i < allIngredients.length; i++) {
            var tempObjIngredient = allIngredients[i];
            var ingredientId = tempObjIngredient.id;
            var booleanNoDetect = true;
            for (let compt = 0; compt < ingredientsRecipe.length; compt++) {
                var tempObjRecipeIngredient = ingredientsRecipe[compt];
                var recipeIngredientId = tempObjRecipeIngredient.ingredientId;
                if (ingredientId === recipeIngredientId) {
                    booleanNoDetect = false;
                    compt = ingredientsRecipe.length;
                }
            }
            if (booleanNoDetect) {
                ingredientsListNotInRecipe.push(tempObjIngredient);
            }    
        }
        return ingredientsListNotInRecipe;
    }
    handleModifyIngredientsinRecipe = (e) => {
       e.preventDefault();
       getIngredients(this.props.auth.kc_token)
                .then(response => {
                    if (response) {
                        var ingredientsListNotInRecipe = this.getListofIngredientNotInRecipeList(this.state.ingredientsRecipe, response);
                        
                        
                        this.setState({ ingredientsListNotInRecipe: ingredientsListNotInRecipe, modifyIngredientsinRecipe: true, allIngredients:response });

                    }
                })
                .catch(error => {
                    console.error("There was an issue getting the ingredients:", error);
                });
        
       // e.preventDefault();
       // this.setState({ addNewIngredientInRecipe: true });
    }


    handleCancel(e) {
        e.preventDefault();
        this.props.onCancel();
    }

    handleSave = () => {
        this.setState({ inputSearch: this.props.recipe.globalQuantity });  
        this.getIngredientForRecipe();

    }

    componentDidMount() {
        this.getIngredientForRecipe();
    }

    estimateIngredient = () => {
        
        var number = this.state.inputSearch;
        var nbreOfPersonForRecipe = this.state.recipe.globalQuantity;
        var ingredientsOfRecipe = this.state.ingredientsRecipe;
        var temp_ingredientList = [];
        for (var i = 0; i < ingredientsOfRecipe.length; i++) {
            var tempObj = ingredientsOfRecipe[i];
            var quantityIngredient = parseFloat(tempObj.quantity);
            var quotient = parseFloat(number / nbreOfPersonForRecipe);
            var estimateValue = parseFloat(quantityIngredient * quotient);
            tempObj.quantityEstimate = estimateValue;
            temp_ingredientList.push(tempObj);
        }
        this.setState({ ingredientsRecipe: temp_ingredientList, modifyIngredientsinRecipe: false });

    }

    getIngredientForRecipe = () => {
        getIngredientsRecipe(this.props.auth.kc_token, this.state.recipe.id)
            .then(response => {
                if (response) {
                    
                    var temp_response = [];
                    for (var i = 0; i < response.length; i++) {
                        var tempObj = response[i];
                        tempObj.quantityEstimate =tempObj.quantity;
                        temp_response.push(tempObj);
                    }
                    this.setState({ ingredientsRecipe: temp_response, modifyIngredientsinRecipe:false });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the recipe ingreidents:", error);
            });
    }
    render() {
        
        if (this.state.modifyIngredientsinRecipe ===true) {
            return <RecipeModifyIngredients auth={this.props.auth} recipe={this.state.recipe} ingredientAlreadyInList={this.state.ingredientsRecipe} ingredientsListNotInRecipe={this.state.ingredientsListNotInRecipe} allIngredients={this.state.allIngredients} onSave={this.handleSave} />;
        }

        return (
            <>
                <FormGroup row>
                    <Label for="card" sm={0}></Label>
                    <Col sm={8}>
                        <Card name="card" body color="light" style={{ width: 'auto' }}>
                            <CardBody>

                                <CardTitle tag="h5">{this.state.recipe.name}</CardTitle>
                                <CardSubtitle className="mb-2 text-muted" tag="h6">
                                    Variante:{this.state.recipe.variant}
                                </CardSubtitle>
                                <CardText>Quantité de la recette:{this.state.recipe.globalQuantity} personnes</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <br></br>
                        <Button onClick={(e) => this.handleCancel(e)}>Retour à la liste</Button>
                        <br></br>
                        <FormGroup row>
                            <Label for="estimationNombrePersonne" sm={0}></Label>
                            <Col sm={3}>
                                <Input type="number" name="estimationNombrePersonne" id="estimationNombrePersonne" placeholder="Nbres de personnes" value={this.state.inputSearch} onChange={this.handleChange.bind(this)} />
                            </Col>
                            <Col sm={6}>
                                <Button color="primary" onClick={this.estimateIngredient }>Estimer les quantités</Button>
                            </Col>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <Table>
                    <thead>
                        <tr>
                            <th>Nom de l'ingrédient</th>
                            <th>Variante</th>
                            <th>Quantité</th>
                        </tr>
                    </thead>
                    <tbody style={{overflow:"auto"} }>
                        {this.state.ingredientsRecipe.map(ingredientRecipe => (
                            <tr key={ingredientRecipe.id}>
                                <td>{ingredientRecipe.ingredientName}</td>
                                <td>{ingredientRecipe.ingredientVariant}</td>
                                <td>{ingredientRecipe.quantityEstimate}  {ingredientRecipe.unitRef}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button color="primary" onClick={(e) => this.handleModifyIngredientsinRecipe(e)}>Modifier les ingrédients de la recette</Button>
            </>

        );
    };

}