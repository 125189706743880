import React, { Component } from 'react';
import { Table, Card, Input, FormGroup, Button } from 'reactstrap';
import { patchExecReq } from "../../datafetchs/common";
import { ingredientsUrl } from "../../datafetchs/datafetchUrls";

export default class StockEdition extends Component {
    static displayName = StockEdition.name;

    constructor(props) {
        super(props);
        //this.toggle = this.toggle.bind(this);
        console.debug("Entering component=StockEdition");
    }
    state = {
        ingredients: [],
        loading: true,
        modal: false,
        id_ingredientToDelete: 0,
        color: "",
        message:""
    };

    handleChange = (e) => {
        var newStock = e.target.value;
        var ingredientId = parseInt(e.target.parentNode.parentNode.getAttribute("postid"));
        var allIngredients = this.state.ingredients;
        for (var i = 0; i < allIngredients.length; i++) {
            if (allIngredients[i].id === ingredientId) {
                if (newStock.includes(",")) {
                    newStock = newStock.replace(",", ".");
                }
                allIngredients[i].stock = newStock;
                allIngredients[i].disableEditSaveButton = false;
                i = allIngredients.length;
            }
        }
        this.setState({ ingredients: allIngredients });
    }
    handleCancel(e) {
        this.props.onSave();
    }

    handleSaveStock = (e) => {
        e.preventDefault();
        var ingredientId = parseInt(e.target.parentNode.getAttribute("postid"));
        var allIngredients = this.state.ingredients;
        var ingredient = {};
        for (var i = 0; i < allIngredients.length; i++) {
            if (allIngredients[i].id === ingredientId) {
                ingredient = allIngredients[i];
                allIngredients[i].disableEditSaveButton = "true";
                i = allIngredients.length;
            }
        }
        patchExecReq(`${ingredientsUrl}/${ingredientId}`, this.props.auth.kc_token, ingredient)
            .then(response => {
                if (!response.ok) {
                    this.setState({ message: "Erreur de sauvegarde, merci de réessayer", color:"red" });
                    throw new Error('Failed to edit ingredient');

                }
                else {
                    this.setState({ message: "Mise à jour réussie", color: "green" });
                    
                    
                }
            })
        
    }
    handleSaveAll=()=> {
        var allIngredients = this.state.ingredients;
        this.setState({ message: "En cours" });
            
        patchExecReq(`${ingredientsUrl}/`, this.props.auth.kc_token, allIngredients)
                .then(response => {
                    if (!response.ok) {
                        this.setState({ errorMessage: "Failed to edit the stock ingredient, try again" });
                        throw new Error('Failed to edit ingredient');
                    }
                }).then(() => {
                    this.props.onSave();
                })    

    }


    render() {
        let myValue = this.state.ingredients;

        if (this.state.ingredients.length !== this.props.ingredientFiltering.length && this.props.ingredientFiltering) {
            myValue = this.props.ingredientFiltering;
            this.setState({ ingredients: myValue });
        }
        return (
            <>
                <div style={{ color: this.state.color }}>{this.state.message}</div>
                <Card name="card" style={{ width: 'auto', height: "500px", overflow: "scroll", marginTop: "10px", marginBottom: "10px", paddingLeft: "10px" }}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Variante</th>
                                <th>Catégorie</th>
                                <th>Stock</th>
                                <th>Unité de mesure</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myValue.map(ingredient => (
                                <tr key={ingredient.id} postid={ingredient.id}>
                                    <td>{ingredient.name}</td>
                                    <td>{ingredient.variant}</td>
                                    <td>{(ingredient.category && ingredient.category !== " ") ? `${ingredient.category}` : "NA"}</td>
                                    <td postid={ingredient.id}>
                                    <FormGroup>
                                        <Input type="text" name="stock" id="stock" value={ingredient.stock} onChange={this.handleChange.bind(this)}>
                                        </Input>
                                    </FormGroup>
                                    </td>
                                    <td>{ingredient.primaryunit}</td>
                                    <td postid={ingredient.id}><Button disabled={ingredient.disableEditSaveButton} onClick={this.handleSaveStock.bind(this)}>Enregistrer</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
                <Button color="sucess" onClick={this.handleSaveAll }>Enregistrer tout</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Retour</Button>
            </>

        );
    }
}
