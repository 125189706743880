import React, { Component } from 'react';
import { Button, FormGroup,Input, Label, Col } from 'reactstrap';
import IngredientList from './IngredientList';
import IngredientEdit from './IngredientEdit';
import IngredientAdd from './IngredientAdd';
import IngredientEstimation from './IngredientEstimation';
import { getIngredients } from '../../datafetchs/ingredients';
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import RefreshProvider from "../auth/RefreshProvider";

export class IngredientManagementInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingIngredient: null,
            addingIngredient: false,
            estimateIngredient:false,
            ingredientFiltering: [],
            inputSearch: '',
            selectSearch:"Nom"
        };
    }

    handleEdit = (ingredient) => {
        this.setState({ editingIngredient: ingredient });
    }
    handleCancelState = () => {
        this.setState({ editingIngredient: null, addingIngredient: false, estimateIngredient: false });
    }
    handleSave = () => {
        // Once the ingredient is saved or added, revert back to the list
        this.getAllIngredients();
        this.setState({ editingIngredient: null, addingIngredient: false, estimateIngredient: false, });
    }

    handleAddClick = () => {
        this.setState({ addingIngredient: true });
    }
    handleEstimationClick = () => {
        this.setState({ estimateIngredient: true });
    }
    handleChange = (e) => {
        if (e.target.name === "searchIngredient") {
            this.setState({ inputSearch: e.target.value });
        }
        if (e.target.name === "selectCriteria") {
            this.setState({ selectSearch: e.target.value });
        }
  
    }
    componentDidMount() {
        this.getAllIngredients();
    }
    getAllIngredients = () => {
        getIngredients(this.props.auth.kc_token)
            .then(response => {
                if (response) {
                    this.setState({ allIngredients: response, ingredientFiltering: response });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the ingredients:", error);
            });
    }

    doSearch = () => { 
        var data = this.state.allIngredients;
        var ingredientAfterFiltering = [];
        var searchField = this.state.inputSearch;
        var temp_Ingredients = {};
        if (this.state.selectSearch === "Nom") {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];
                
                var temp_name = temp_Ingredients.name;
                if (temp_name.toUpperCase().includes(searchField.toUpperCase())) {
                    ingredientAfterFiltering.push(data[i]);
                }
            }
        }
        else if (this.state.selectSearch === "Catégorie") {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];
                
                var temp_category = temp_Ingredients.category;
                if (temp_category !== null) {
                    if (temp_category.toUpperCase().includes(searchField.toUpperCase())) {
                        ingredientAfterFiltering.push(data[i]);
                    }
                }
            }
        }
        else {
            for (let i = 0; i < data.length; i++) {
                temp_Ingredients = data[i];
                
                var temp_supplier = temp_Ingredients.supplier;
                if (temp_supplier !== null) {
                    if (temp_supplier.toUpperCase().includes(searchField.toUpperCase())) {
                        ingredientAfterFiltering.push(data[i]);
                    }
                }
            }

        }
        this.setState({ ingredientFiltering: ingredientAfterFiltering });
    }

    handleKeyPress = (e) => {

        if (e.key === "Enter") {
            this.doSearch();
        }
    }

    render() {
        if (this.state.editingIngredient) {
            return <IngredientEdit auth={this.props.auth} ingredient={this.state.editingIngredient} onSave={this.handleSave} onCancel={this.handleCancelState} />;
        }

        if (this.state.addingIngredient) {
            return <IngredientAdd auth={this.props.auth} onSave={this.handleSave} onCancel={this.handleCancelState} />;
        }
        if (this.state.estimateIngredient) {
            return <IngredientEstimation auth={this.props.auth} onSave={this.handleSave} onCancel={this.handleCancelState} ingredientsList={this.state.allIngredients} />;
        }
        
        
        return (
            <>
                <FormGroup row>
                    <Label for="searchIngredient" sm={1.5}>Rechercher par</Label>
                    <Col sm={2}>
                        <Input type="select" name="selectCriteria" id="selectCriteria" value={this.state.selectSearch} onChange={this.handleChange.bind(this)} >
                            <option>Nom</option>
                            <option>Catégorie</option>
                            <option>Fournisseur</option>
                        </Input>
                    </Col>
                    <Col sm={6}>
                        <Input type="search" name="searchIngredient" id="searchIngredient" value={this.state.inputSearch} onChange={this.handleChange.bind(this)} onKeyPress={(e) => { this.handleKeyPress(e) }} placeholder="Paramètre de recherche" />
                    </Col>
                    <Col sm={2}>
                        <Button color="primary"  onClick={this.doSearch}>Rechercher</Button>
                    </Col>
                </FormGroup>
                <IngredientList auth={this.props.auth} ingredientFiltering={this.state.ingredientFiltering} onEdit={this.handleEdit} onSave={this.handleSave} />
                <Button color="primary" onClick={this.handleAddClick} style={{marginRight:"5px"} }>Ajouter un nouvel ingrédient</Button>
                <Button onClick={this.handleEstimationClick}>Estimer le prix d'ingrédients</Button>
            </>
        );
    }
}

const IngredientManagement = () => {
    const authState = useSelector((state) => state.auth);
    if ( (!authState.authenticated) && (!authState.kc_roles.includes("AdminRole")) ) {
        return <Navigate to="/" />
    }
    return (
        <RefreshProvider>
            <IngredientManagementInner auth={authState}/>
        </RefreshProvider>
    );
};

export default IngredientManagement;