
import React, { Component } from 'react';



export default class KnowledgeManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
           

        };
    }
    render() {

        return (
            <>
                <div>
                    <p>
                        Page en création.
                    </p>
                </div>
            </>
        );
    }
}