import {administrationFieldsUrl} from "./datafetchUrls";
import {getExecReq, putExecReq} from "./common";

export const getAdministrationFields = async (token) => {
    let ret = [];
    const response = await getExecReq(administrationFieldsUrl, token)
    ret = await response.json();
    return ret;
};

export const updateAdministrationFields = async (token, administrationFields) => {
    const response = await putExecReq(administrationFieldsUrl, token, administrationFields);
    const data = await response.json();
    return data;
}