import {delExecReq, getExecReq} from "./common";
import {ingredientsUrl} from "./datafetchUrls";

export const getIngredients = async (kc_token) => {
    let ret = [];
    const resp = await getExecReq(ingredientsUrl, kc_token)
    ret = await resp.json();
    return ret;
};

export const deleteIngredient = async (id, kc_token) => {
    let ret = { statusCode: 200, message: "OK" };
    const reqUrl = `${ingredientsUrl}/${id}`;
    const response = await delExecReq(reqUrl, kc_token);
    ret.statusCode = response.status;
    if (response.status === 400) {
        
        const data = await response.json();
        let messageVide = "L'ingrédient n'est pas supprimé. Il est utilisé dans" + data.length + " recettes. \n Détails des recettes:";
        for (let i = 0; i < data.length; i++) {
            messageVide = messageVide+"\n" + data[i].name + " " + data[i].variant;
        }
        ret.message = messageVide;
    }
   return ret;
}