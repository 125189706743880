import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col, Card, CardBody, CardTitle,CardSubtitle,CardText } from 'reactstrap';
import { updateIngredientRecipe } from '../../datafetchs/recipes';

export default class RecipeEditIngredient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe:props.recipe,
            ingredientToEdit: props.ingredientToEdit || {},
            disableButton: true,
            unitRef:props.ingredientToEdit.unitRef,
            errorMessage:""
        };
    }

    handleChange(e) {
        const { name, value } = e.target;
       
        this.setState(prevState => ({
            ingredientToEdit: {
                ...prevState.ingredientToEdit,
                [name]: value
            },
            disableButton:false
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.ingredientToEdit.unitRef !== "" && this.state.ingredientToEdit.quantity !== "" && this.state.ingredientToEdit.quantity !== "0") {
            updateIngredientRecipe(this.props.auth.kc_token, this.state.ingredientToEdit).then(response => {
                this.props.onSudmitEdition();
            })
                .catch(error => {
                    console.error("There was an issue removing the recipe ingriedents:", error);
                });
        }
        else {
            this.setState({ errorMessage: "Merci de vérifier que la quantité et l'unité ne sont pas vides" });
        }
    }
            
        

    handleCancel(e) {
        e.preventDefault();
        this.props.onSave();
    }

    render() {
        return (
            <>
                <Card name="card" body color="light" style={{ width: 'auto' }}>
                    <CardBody>

                        <CardTitle tag="h5">{this.state.recipe.name}</CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                            Variante:{this.state.recipe.variant}
                        </CardSubtitle>
                        <CardText>Quantité de la recette:{this.state.recipe.globalQuantity} personnes</CardText>
                    </CardBody>
                </Card>
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Label for="ingredientName">Nom de l'ingrédient : {this.state.ingredientToEdit.ingredientName}</Label>
                </FormGroup>
                <FormGroup>
                    <Label for="variant">Variante de l'ingrédient:{this.state.ingredientToEdit.ingredientVariant} </Label>
                </FormGroup>
                <FormGroup row>
                    <Label for="quantity">Quantité de l'ingrédient</Label>
                    <Col>
                        <Input type="text" name="quantity" id="quantity" value={this.state.ingredientToEdit.quantity || ''} onChange={this.handleChange.bind(this)} />
                    </Col>
                    <Col>
                            <Input type="select" name="unitRef" id="unitRef" value={this.state.ingredientToEdit.unitRef || ''} onChange={this.handleChange.bind(this)} >
                                <option>{this.state.ingredientToEdit.unitRef ? this.state.ingredientToEdit.unitRef:this.state.unitRef}</option>
                        <option>
                            {(this.state.ingredientToEdit.ingredientEstimationunit && this.state.ingredientToEdit.ingredientEstimationunit !== "0" && this.state.ingredientToEdit.unitRef !== "Unité") && "Unité"}
                        </option>
                        </Input>
                    </Col>
                    </FormGroup>
                    <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                <Button color="sucess" type="submit" disabled={this.state.disableButton}>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
                </Form>
            </>
        );
    }
}