import React, { Component } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card } from 'reactstrap';
import { deleteIngredient } from '../../datafetchs/ingredients';

export default class StockList extends Component {
    static displayName = StockList.name;

    constructor(props) {
        super(props);
        //this.toggle = this.toggle.bind(this);
        console.debug("Entering component=StockList");
    }
    state = {
        ingredients: [],
        loading: true,
        modal: false,
        id_ingredientToDelete: 0
    };
    

    render() {
        let myValue = this.state.ingredients;

        if (this.state.ingredients.length !== this.props.ingredientFiltering.length && this.props.ingredientFiltering) {
            myValue = this.props.ingredientFiltering;
        }
        return (
            <Card name="card" style={{ width: 'auto', height: "500px", overflow: "scroll", marginTop: "10px", marginBottom: "10px", paddingLeft: "10px" }}>
                <Table>
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Variante</th>
                            <th>Catégorie</th>
                            <th>Fournisseur</th>
                            <th>Stock</th>
                            <th>Etat du stock</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myValue.map(ingredient => (
                            <tr key={ingredient.id} postid={ingredient.id}>
                                <td>{ingredient.name}</td>
                                <td>{ingredient.variant}</td>
                                <td>{(ingredient.category && ingredient.category !== " ") ? `${ingredient.category}` : "NA"}</td>
                                <td>{(ingredient.supplier && ingredient.supplier !== " ") ? `${ingredient.supplier}` : "NA"}</td>
                                <td>{ingredient.stock} {ingredient.primaryunit}</td>
                                <td style={{ color: ingredient.stateColor, fontWeight: "bold" }}>{ingredient.stateStock}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card>

        );
    }
}
