
import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Col, Card,CardBody,CardTitle } from 'reactstrap';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getRecipe } from '../../datafetchs/recipes';
import { getCalculation } from '../../datafetchs/calculator';
import { getAdministrationFields } from '../../datafetchs/administrationFields';
import CalculatorGlobalInformation from './CalculatorGlobalInformation';
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import RefreshProvider from "../auth/RefreshProvider";

class CalculatorManagementInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRecipes: [],
            thalies: 1,
            families:1,
            submitted: false,
            allRecipes: [],
            calculationFields: {},
            numberOfPersonForThaliesRecipe: 0,
            numberOfPersonForFamiliesRecipe: 0,
            recipeByThalies: [],
            recipeByFamilies: [],
            errorMessage: "",
            ingredientListByRecipe: [],
            ingredientListsTotal: [],
            ingredientsListByCategory:[],
            totalCost: 0
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        console.debug("Entering component=CalculatorManagementInner");
    }
    componentDidMount() {
        getRecipe(this.props.auth.kc_token)
            .then(response => {
                if (response) {
                    this.setState({ allRecipes: response });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the recipe:", error);
            });

        getAdministrationFields(this.props.auth.kc_token)
            .then(response => {
                if (response) {

                    this.setState({ calculationFields: response[0] });
                }
            })
            .catch(error => {
                console.error("There was an issue getting the parameters:", error);
            });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    searchRecipe =(id) =>{
        var allRecipe = this.state.allRecipes;
        var recipe = {};
        for (var i = 0; i < allRecipe.length; i++) {
            if (allRecipe[i].id === id) {
                recipe = allRecipe[i];
                
            }
        }
        return recipe;
    }
    reset = () => {
        this.setState({
            selectedRecipes: [],
            thalies: 1,
            families: 1,
            submitted: false,
            numberOfPersonForThaliesRecipe: 0,
            numberOfPersonForFamiliesRecipe: 0,
            recipeByThalies: [],
            recipeByFamilies: [],
            errorMessage: "",
            ingredientListByRecipe: [],
            ingredientListsTotal: [],
            totalCost: 0
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.selectedRecipes.length !== 0) {
            var recipeByFamilies = [];
            var recipeByThalies = [];
            var allRecipesSelected = [];
            for (var i = 0; i < this.state.selectedRecipes.length; i++) {
                var recipe = this.searchRecipe(this.state.selectedRecipes[i].value);
                
                var numberOfRecipeByFamily = 0;
                var numberOfRecipeByThalies = 0;
                if (recipe.isOneByFamily) {
                    allRecipesSelected.push(recipe);
                    recipeByFamilies.push(recipe);
                    numberOfRecipeByFamily = numberOfRecipeByFamily + 1;

                }
                else {
                    allRecipesSelected.push(recipe);
                    recipeByThalies.push(recipe);
                    numberOfRecipeByThalies = numberOfRecipeByThalies + 1;
                }
            }
            var numberOfPersonForThaliesRecipe = 0;
            var numberOfPersonForFamiliesRecipe = 0;
            if (recipeByThalies.length !== 0) {
                numberOfPersonForThaliesRecipe = this.state.calculationFields.thalisParameters * this.state.thalies;
            }
            if (recipeByFamilies.length !== 0) {
                numberOfPersonForFamiliesRecipe = this.state.calculationFields.familyParameter * this.state.families;
            }
            var requestObj = {};
            requestObj.recipeList = allRecipesSelected;
            requestObj.numberOfPersonFamily = numberOfPersonForFamiliesRecipe;
            requestObj.numberOfPersonsThaly = numberOfPersonForThaliesRecipe;
            requestObj.ratio = parseFloat(this.state.calculationFields.ratio);
            getCalculation(this.props.auth.kc_token, requestObj).then(
                (response) => {
                    var ingredientListsTotal = response.ingredientTotalList;
                    var ingredientListByRecipe = response.ingredientsListByRecipe;
                    var ingredientsListByCategory = response.ingredientsListByCategory;
                    var totalCost = response.totalCost;
                    console.log(ingredientListByRecipe);
                    this.setState({ recipeByFamilies: recipeByFamilies, recipeByThalies: recipeByThalies, numberOfPersonForThaliesRecipe: numberOfPersonForThaliesRecipe, numberOfPersonForFamiliesRecipe: numberOfPersonForFamiliesRecipe, submitted: true, errorMessage: "", ingredientListsTotal: ingredientListsTotal, ingredientListByRecipe: ingredientListByRecipe, totalCost: totalCost, ingredientsListByCategory: ingredientsListByCategory });
                }
            )
        }
        else {
            this.setState({ errorMessage: "Merci de sélectionner des recettes", submitted:false })
        }
       
    }

    render() {
        var options = [];
        for (let i = 0; i < this.state.allRecipes.length; i++) {
            options[i] = { value: this.state.allRecipes[i].id, label: this.state.allRecipes[i].name +" "+ this.state.allRecipes[i].variant }
        }

        return (
            <div className="container mt-5">
                <Card>
                    <CardBody>
                        <CardTitle tag="h5">Paramétres de simulation</CardTitle>
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label>Recettes</Label>
                                <Select
                                    isMulti
                                    name="recipes"
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={selectedOptions => this.setState({ selectedRecipes: selectedOptions })}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label for="thalies">Nombre de thalies</Label>
                                    <Input type="number" name="thalies" id="thalies" value={this.state.thalies} onChange={this.handleChange} min="1" />
                                </Col>
                                <Col>
                                <Label for="families">Nombre de familles</Label>
                                    <Input type="number" name="families" id="families" value={this.state.families} onChange={this.handleChange} min="1" />
                                </Col>
                            </FormGroup>
                            <Button color="sucess" type="submit">Calculer</Button>{' '}
                            <Button onClick={this.reset }>Réinitialiser</Button>
                            <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                        </Form>
                    </CardBody>
                </Card>
                <CalculatorGlobalInformation auth={this.props.auth} submitted={this.state.submitted} selectedRecipes={this.state.selectedRecipes} calculationFields={this.state.calculationFields} numberOfThalies={this.state.thalies} numberOfFamilies={this.state.families} numberOfPersonForThaliesRecipe={this.state.numberOfPersonForThaliesRecipe} numberOfPersonForFamiliesRecipe={this.state.numberOfPersonForFamiliesRecipe} recipeByThalies={this.state.recipeByThalies} recipeByFamilies={this.state.recipeByFamilies} totalCost={this.state.totalCost} ingredientListByRecipe={this.state.ingredientListByRecipe} ingredientListsTotal={this.state.ingredientListsTotal} ingredientsListByCategory={this.state.ingredientsListByCategory}></CalculatorGlobalInformation>
                
                </div>
        );
    }
}


const CalculatorManagement = () => {
    const authState = useSelector((state) => state.auth);
    if ( (!authState.authenticated) && (!authState.kc_roles.includes("AdminRole")) ) {
        return <Navigate to="/" />
    }
    return (
        <RefreshProvider>
            <CalculatorManagementInner auth={authState}/>
        </RefreshProvider>
    );
};

export default CalculatorManagement;