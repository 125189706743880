import { Home } from "./components/Home";
import IngredientManagement from "./components/ingredients/IngredientManagement";
import CalculatorManagement from "./components/calculator/CalculatorManagement";
import RecipeManagement from "./components/recipes/RecipeManagement";
import AdministrationManagement from "./components/administration/AdministrationManagement";
import KnowledgeManagement from "./components/knowledge/KnowledgeManagement";
import StockManagement from "./components/stock/StockManagement"

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: "/ingredients",
    element: <IngredientManagement />
  },
  {
    path: "/calculator",
      element: <CalculatorManagement />
  },
  {
    path: "/recipes",
      element: <RecipeManagement />
  },
  {
    path: "/administrator",
      element: <AdministrationManagement />
  },
  {
      path: '/knowledgeHalalHaram',
    element: <KnowledgeManagement />
  },
  {
      path: '/stock',
    element: <StockManagement />
  }
];

export default AppRoutes;
