import React, { Component } from 'react';
import { Card, CardBody, Col, Table, FormGroup, Label, CardText, CardSubtitle, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteStepRecipe, getStepsRecipe } from '../../datafetchs/recipes';
import RecipeEditStep from './RecipeEditStep';
import RecipeAddStep from './RecipeAddStep';

export default class RecipeViewSteps extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        console.debug("Entering component=RecipeViewSteps");
    };
    state = {       
        recipe: this.props.recipe || {},
        stepsRecipe: [],
        stepTodeleteId:0,
        modal: false,
        changeRecipeStep: null,
        addStep:false
};

    toggle(e) {
        if (e) {
            var stepTodelete = e.target.parentNode.getAttribute("postid");
            e.preventDefault();
            this.setState({
                modal: !this.state.modal,
                stepTodeleteId: stepTodelete
            });
        }
        else {
            this.setState({
                modal: !this.state.modal,
                stepTodeleteId: 0
            });
        }
    }

    handleChangeStep = (stepRecipe) => {
        this.setState({
            changeRecipeStep: stepRecipe
        });

    }
    addRecipeStep = () => {
        this.setState({
            addStep: true
        });

    }

    handleSave = () => {
        this.getStepForRecipe();
    }

    handleCancel(e) {
        e.preventDefault();
        this.props.onCancel();
    }

    handleCancelForChildren=(e)=> {
       
        this.setState({
            changeRecipeStep: null,addStep:null
        });
    }

    handleDelete(e) {
        var id = this.state.stepTodeleteId;
        this.toggle();
        deleteStepRecipe(this.props.auth.kc_token, id).then((response) => {
            this.getStepForRecipe();
        });
    }

    componentDidMount() {
        this.getStepForRecipe();
    }
    getStepForRecipe=()=>{
    getStepsRecipe(this.props.auth.kc_token, this.state.recipe.id)
        .then(response => {
        if (response) {

            this.setState({ stepsRecipe: response, changeRecipeStep:null, addStep:false });
        }
        })
            .catch (error => {
    console.error("There was an issue getting the recipe steps:", error);
            });
    }

    render() {
        if (this.state.changeRecipeStep) {
            return <RecipeEditStep auth={this.props.auth} stepToEdit={this.state.changeRecipeStep} recipe={this.state.recipe} onSave={this.handleSave} onCancel={this.handleCancelForChildren} />;
        }
        if (this.state.addStep) {
            return <RecipeAddStep auth={this.props.auth} onSave={this.handleSave} recipe={this.state.recipe} lastStep={this.state.stepsRecipe[this.state.stepsRecipe.length - 1].stepOrder} onCancel={this.handleCancelForChildren} />;
        }
        return (

            <>
            <FormGroup row>
                <Label for="card" sm={0}></Label>
                <Col sm={8}>
                <Card name="card" body color="light" style={{ width: 'auto'}}>
                    <CardBody>
                    
                        <CardTitle tag="h5">{this.state.recipe.name}</CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                            Variante:{this.state.recipe.variant} 
                        </CardSubtitle>
                            <CardText>Quantité de la recette:{this.state.recipe.globalQuantity}</CardText>
                    </CardBody>
                </Card>
                </Col>
                    <Col sm={4}>
                    <Button onClick={(e) => this.handleCancel(e)}>Retour à la liste</Button>
                    </Col>
            </FormGroup>
                <Table>
                    <thead>
                        <tr>
                            <th>Etapes</th>
                            <th>Description de l'étape</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.stepsRecipe.map(stepRecipe => (
                            <tr key={stepRecipe.id} postid={stepRecipe.id}>
                                <td>Etape {stepRecipe.stepOrder}</td>
                                <td>{stepRecipe.stepDescription}</td>
                                <td postid={stepRecipe.id}>
                                    <Button onClick={() => this.handleChangeStep(stepRecipe)}>Ajuster l'étape dans la recette</Button>
                                    {' '}
                                    <Button color="danger" id={stepRecipe.id} onClick={this.toggle}>Retirer l'étape de la recette</Button>
                                    <Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
                                        <ModalHeader toggle={() => this.toggle()}>Confirmation suppresion</ModalHeader>
                                        <ModalBody>
                                            Êtes-vous sure de vouloir retirer l'étape ?'
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="primary" onClick={(e) => this.handleDelete(e,stepRecipe.id)}>
                                                Oui
                                            </Button>{' '}
                                            <Button color="secondary" onClick={this.toggle}>
                                                Non
                                            </Button>
                                        </ModalFooter>
                                    </Modal>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button color="primary" onClick={this.addRecipeStep}>Ajouter une nouvelle étape à la recette</Button>
            </>
              
        );
    };

}