import React, { Component } from 'react';
import { Button, Card, Form, CardBody, FormGroup, Label, Collapse, Table, Input } from 'reactstrap';
import Select from 'react-select';


export default class StockEstimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectIngredients: [],
            selectIngredientsDetails: [],
            stockEstimationResult: [],
            choiceOfIngredients: true,
            showQuantityTable: false,
            showResultTable: false,
            total: 0
        };
    }
    handleChange = (e) => {

        var id = parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("postid"));
        var value = e.target.value;

        var selectIngredientsDetails = this.state.selectIngredientsDetails;

        for (var i = 0; i < selectIngredientsDetails.length; i++) {

            if (id === selectIngredientsDetails[i].id) {

                selectIngredientsDetails[i].quantityEstimation = value;
                i = selectIngredientsDetails.length;
            }
        }
        this.setState({ selectIngredientsDetails: selectIngredientsDetails })


    }

    returnStep = () => {
        if (this.state.showQuantityTable) {
            this.setState({ choiceOfIngredients: true, showQuantityTable: false })
        }
        if (this.state.showResultTable) {
            this.setState({ showQuantityTable: true, showResultTable: false })
        }
    }

    showResult = () => {
        var selectIngredientsDetails = this.state.selectIngredientsDetails;
        var stockEstimationResult = [];
        var total = 0;
        for (var i = 0; i < selectIngredientsDetails.length; i++) {
            var temp_obj = selectIngredientsDetails[i];
            if (temp_obj.quantityEstimation.includes(",")) {
                temp_obj.quantityEstimation = temp_obj.quantityEstimation.replace(",", ".");
            }
            var quantiteEstimationNumber = parseFloat(temp_obj.quantityEstimation);
            if (isNaN(quantiteEstimationNumber)) {
                quantiteEstimationNumber = 0;
            }
            
            var ingredientStockEstimationResult = temp_obj.stock - quantiteEstimationNumber;
            if (ingredientStockEstimationResult > temp_obj.limitstock) {
                temp_obj.ingredientStockEstimationResult = ingredientStockEstimationResult;
                temp_obj.stateStockEstimationResult = "Stock large";
                temp_obj.stateColorEstimationResult = "green";
            }
            else if (ingredientStockEstimationResult < temp_obj.limitstock && ingredientStockEstimationResult >= 0) {
                temp_obj.ingredientStockEstimationResult = ingredientStockEstimationResult;
                temp_obj.stateStockEstimationResult = "Stock suffisant mais passant sous le seuil";
                temp_obj.stateColorEstimationResult = "orange";
            }
            else {
                temp_obj.ingredientStockEstimationResult = ingredientStockEstimationResult;
                temp_obj.stateStockEstimationResult = "Stock insuffisant";
                temp_obj.stateColorEstimationResult = "red";

            }
           
            //temp_obj.estimationPrice = estimationPrice;
            //total = total + estimationPrice;
            stockEstimationResult.push(temp_obj);
        }
        this.setState({ total: total, stockEstimationResult: stockEstimationResult, showQuantityTable: false, showResultTable: true });
    }
    quantityTableShow = () => {
        if (this.state.selectIngredients.length !== 0) {
            var selectIngredientsDetails = [];
            for (var i = 0; i < this.state.selectIngredients.length; i++) {
                for (var j = 0; j < this.props.ingredientsList.length; j++) {
                    if (this.state.selectIngredients[i].value === this.props.ingredientsList[j].id) {
                        var temp_obj = this.props.ingredientsList[j];
                        temp_obj.quantityEstimation = "0";
                        selectIngredientsDetails.push(temp_obj);
                    }
                }
            }

            this.setState({ choiceOfIngredients: false, showQuantityTable: true, selectIngredientsDetails: selectIngredientsDetails });
        }
    }




    render() {
        var options = [];
        for (let i = 0; i < this.props.ingredientsList.length; i++) {
            options[i] = { value: this.props.ingredientsList[i].id, label: this.props.ingredientsList[i].name + " " + this.props.ingredientsList[i].variant }
        }
        return (
            <div>
                <Button disabled color="primary" style={{ width: "100%", marginBottom: "1rem" }}>
                    Choix des ingrédients
                </Button>
                <Collapse isOpen={this.state.choiceOfIngredients}>
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label>Ingrédients à estimer</Label>
                                    <Select
                                        isMulti
                                        name="recipes"
                                        options={options}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={selectedOptions => this.setState({ selectIngredients: selectedOptions })}
                                    />
                                </FormGroup>
                                <Button onClick={this.quantityTableShow}>Suivant</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Collapse>
                <Button disabled color="primary" style={{ width: "100%", marginBottom: "1rem" }}>
                    Tableau des ingrédients choisis à estimer
                </Button>
                <Collapse isOpen={this.state.showQuantityTable}>
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Variante</th>
                                        <th>Stock actuel</th>
                                        <th>Etat du stock actuel</th>
                                        <th>Quantité à estimer</th>
                                        <th>Unité</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.selectIngredientsDetails.map(ingredient => (
                                        <tr key={ingredient.id} postid={ingredient.id}>
                                            <td>{ingredient.name}</td>
                                            <td>{ingredient.variant}</td>
                                            <td>{ingredient.stock} {ingredient.primaryunit}</td>
                                            <td style={{ color: ingredient.stateColor, fontWeight: "bold" }}>{ingredient.stateStock}</td>
                                            <td>
                                                <FormGroup>
                                                    <Label for="globalQuantity">Quantité à estimer</Label>
                                                    <Input name="quantityEstimation" id="quantityEstimation" value={ingredient.quantityEstimation || ''} onChange={(e) => { this.handleChange(e) }} />
                                                </FormGroup>
                                            </td>
                                            <td>{ingredient.primaryunit}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button onClick={this.returnStep} style={{ marginRight: "5px" }}>Retour</Button>
                            <Button onClick={this.showResult}>Vérifier le stock</Button>
                        </CardBody>
                    </Card>
                </Collapse>
                <Button disabled color="primary" style={{ width: "100%", marginBottom: "1rem" }}>
                    Tableau de résultat
                </Button>
                <Collapse isOpen={this.state.showResultTable}>
                    <Card>
                        <CardBody>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nom</th>
                                        <th>Variante</th>
                                        <th>Stock actuel</th>
                                        <th>Quantité entrée</th>
                                        <th>Stock après la quantité</th>
                                        <th>Etat du stock après la quantité</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.stockEstimationResult.map(ingredient => (
                                        <tr key={ingredient.id} postid={ingredient.id}>
                                            <td>{ingredient.name}</td>
                                            <td>{ingredient.variant}</td>
                                            <td>{ingredient.stock} {ingredient.primaryunit}</td>
                                            <td>{ingredient.quantityEstimation} {ingredient.primaryunit}</td>
                                            <td>{ingredient.ingredientStockEstimationResult} {ingredient.primaryunit}</td>
                                            <td style={{ color: ingredient.stateColorEstimationResult,fontWeight:"bold" }}>{ingredient.stateStockEstimationResult}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <Button onClick={this.returnStep} style={{ marginRight: "5px" }}>Retour</Button>
                </Collapse>
                <Button onClick={this.props.onCancel} style={{ marginTop: "5px" }}>Retour gestion stock</Button>
            </div>

        );
    }
}
