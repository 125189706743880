import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input,Card,CardBody,CardTitle,CardSubtitle,CardText } from 'reactstrap';
import { updateStepRecipe } from '../../datafetchs/recipes';

export default class RecipeEditStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipe: this.props.recipe,
            stepToEdit: props.stepToEdit || {},
            disableButton:true
        };
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            stepToEdit: {
                ...prevState.stepToEdit,
                [name]: value
            },
            disableButton:false
        }));
    }

    handleSubmit(e) {
        e.preventDefault();
        var stepRecipeToEdit = this.state.stepToEdit;
        updateStepRecipe(this.props.auth.kc_token, stepRecipeToEdit).then(()=>{
            this.props.onSave();
        })
        
        /*fetch(`/ingredient/${this.state.ingredient.id}`, {
            // method: this.state.ingredient.id ? 'POST' : 'PUT',
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.ingredient)
        }).then(() => {
            this.props.onSave();
        });*/

    }

    handleCancel(e) {
        e.preventDefault();
        this.props.onCancel();
    }

    render() {
        return (
            <>
                <Card name="card" body color="light" style={{ width: 'auto' }}>
                    <CardBody>

                        <CardTitle tag="h5">{this.state.recipe.name}</CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                            Variante:{this.state.recipe.variant}
                        </CardSubtitle>
                        <CardText>Quantité de la recette:{this.state.recipe.globalQuantity} personnes</CardText>
                    </CardBody>
                </Card>
            <Form onSubmit={this.handleSubmit.bind(this)}>
                <FormGroup>
                    <Label for="stepOrder">Numéro d'étape</Label>
                    <Input type="number" name="stepOrder" id="stepOrder" value={this.state.stepToEdit.stepOrder || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <FormGroup>
                    <Label for="stepDescription">Description de l'étape</Label>
                    <Input type="textarea" name="stepDescription" id="stepDescription" value={this.state.stepToEdit.stepDescription || ''} onChange={this.handleChange.bind(this)} />
                </FormGroup>
                <Button color="sucess" type="submit" disabled={this.state.disableButton} style={{size:"5rem"} }>Enregistrer</Button>
                <Button onClick={(e) => this.handleCancel(e)}>Annuler</Button>
                </Form>
            </>
        );
    }
}